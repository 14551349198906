import React from "react";
import "./PipeLine.css";
function Pipeline(company) {
  return (
    <div className="pipeline_sec">
      <h4>Pipeline</h4>

      <div className="pipeline_list interested_candidate">
        <span>Interested Candidate</span>
        <span>2</span>
      </div>

      <div className="pipeline_list favorite_candidate">
        <span>Favorite Candidate</span>
        <span>2</span>
      </div>

      <div className="pipeline_list submitted_candidate">
        <span>Submitted Candidate</span>
        <span>2</span>
      </div>

      <div className="pipeline_list total_applicants">
        <span>Total Applicants</span>
        <span>2</span>
      </div>

      <div className="pipeline_list disqualified_applicants">
        <span>Disqualified</span>
        <span>2</span>
      </div>
      <div className="pipeline_list sent_offer">
        <span>Sent Offer</span>
        <span>2</span>
      </div>

      <div className="pipeline_list hired">
        <span>Hired</span>
        <span>2</span>
      </div>
    </div>
  );
}

export default Pipeline;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recentactivity_compontent{
    padding: 20px 0px;
}
.activity_main_bar{
    display: flex;
    justify-content: space-between;
}

.activities_box{
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}
.activities_box .box_main_bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.activity_heading{
    color: black;
    font-size: 14px;
    font-weight: 600;
}

.profile_info_img
{
    color: #034A8C;
    display: flex;
    align-items: center;
    
}

.profile_info_img h6{
    font-size: 14px;
    padding-left: 10px;
    padding-bottom: 0;
    margin-bottom: 0px;
}

 .date_time{
    font-size: 14px;
}
.activities_desc p{
    padding-top: 10px;
}

.profile_img_activity{
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
}

.activities_box{
    margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/admin/dashboard/Companies/Recent Activites/RecentActivities.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,uBAAuB;IACvB,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,gBAAgB;AACpB;;AAEA;;IAEI,cAAc;IACd,aAAa;IACb,mBAAmB;;AAEvB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;AACtB;;CAEC;IACG,eAAe;AACnB;AACA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".recentactivity_compontent{\n    padding: 20px 0px;\n}\n.activity_main_bar{\n    display: flex;\n    justify-content: space-between;\n}\n\n.activities_box{\n    background-color: white;\n    padding: 20px;\n    border-radius: 10px;\n}\n.activities_box .box_main_bar{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.activity_heading{\n    color: black;\n    font-size: 14px;\n    font-weight: 600;\n}\n\n.profile_info_img\n{\n    color: #034A8C;\n    display: flex;\n    align-items: center;\n    \n}\n\n.profile_info_img h6{\n    font-size: 14px;\n    padding-left: 10px;\n    padding-bottom: 0;\n    margin-bottom: 0px;\n}\n\n .date_time{\n    font-size: 14px;\n}\n.activities_desc p{\n    padding-top: 10px;\n}\n\n.profile_img_activity{\n    width: 30px;\n    height: 30px;\n    object-fit: cover;\n    border-radius: 50%;\n}\n\n.activities_box{\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

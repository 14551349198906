// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner_course{
    background-color: #011324;
    padding: 20px;
    border-radius: 10px;
}

.banner_course .row{
    align-items: center;
    color: white;
}

`, "",{"version":3,"sources":["webpack://./src/components/courses/CourseBanner.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":[".banner_course{\n    background-color: #011324;\n    padding: 20px;\n    border-radius: 10px;\n}\n\n.banner_course .row{\n    align-items: center;\n    color: white;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

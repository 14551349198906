import React, { useState } from "react";
import "./Certification.css";
import certificationIcon from "../../../../../assets/imgs/certification.png";
import plusIcon from "../../../../../assets/imgs/plusCircle.png";
import AddCertificate from "./AddCertificate/AddCertificate";
import editIcon from "../../../../../assets/imgs/Edit.png";

function Certification({ candidate, hideEditIcon }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedCandidate, setUpdatedCandidate] = useState(candidate);

  const handleUpdate = (updatedData) => {
    setUpdatedCandidate((prev) => ({
      ...prev,
      certifications: updatedData.certifications,
      pursuingCertifications: updatedData.pursuingCertifications,
    }));
  };

  // Modal open karne ka function
  const openModal = () => {
    setIsModalOpen(true); // Show the AddCertificate modal
  };

  // Modal band karne ka function
  const closeModal = () => {
    setIsModalOpen(false); // Close the AddCertificate modal
  };

  return (
    <div className="certification">
      <h4 className="main_certification_heading">Certifications</h4>

      {/* If the modal isn't open, show certification list */}
      {!isModalOpen && (
        <div className="certificate-sections-list">
          {updatedCandidate.certifications?.length > 0 ||
          updatedCandidate.pursuingCertifications?.length > 0 ? (
            <div className="exist-target-value">
              {!hideEditIcon && (
                <div className="editIcon" onClick={openModal}>
                  <img src={editIcon} alt="Edit-icon" />
                </div>
              )}
              <div className="certification-list-options">
                <span className="industry-tag">
                  {updatedCandidate.certifications?.join(", ")}
                </span>
              </div>

              <div className="certification-list-options">
                <span className="industry-tag">
                  {updatedCandidate.pursuingCertifications
                    ?.map((industry) => `${industry} (In-Progress)`)
                    .join(", ")}
                </span>
              </div>
            </div>
          ) : (
            <div className="empty-state">
              <div className="plusIcon" onClick={openModal}>
                <img src={plusIcon} alt="plus-icon" />
              </div>
              <img src={certificationIcon} alt="certification" />
              <p>
                Highlight your skills and qualifications by adding any relevant
                certifications. Stand out to companies
                <br /> looking for your expertise.
              </p>
              <button onClick={openModal}>Add Certifications</button>
            </div>
          )}
        </div>
      )}

      {/* If the modal is open, show AddCertificate component */}
      {isModalOpen && (
        <AddCertificate
          isOpen={isModalOpen}
          onClose={closeModal}
          candidate={updatedCandidate}
          onUpdate={handleUpdate} // Send handleUpdate to the child
        />
      )}
    </div>
  );
}

export default Certification;

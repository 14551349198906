import React, { useState } from "react";
import "./CompanyDetailPopup.css";
import CandidateInfo from "../Company Info/CompanyInfo";
// import JobSearchStatus from "../Job Search Status/JobSearchStatus";
// import Resume from "../Resume/ReductedResume";
// import AboutCandidate from "../../CandidateAbout/AboutCandidate";
// import Certification from "../Certifications/Certification";
import closeIcon from "../../../../../assets/imgs/close.png";
import EmailEditor from "../Email Sender/EmailEditor";
import NotesEditor from "../Notes Editor/NotesEditor";
import RecentActivites from "../Recent Activites/RecentActivites";
import CompaniesJobs from "../../Companies/Companies Jobs/CompaniesJobsListing";
import NotesList from "../Notes List/NotesList";
// import TargetIndustries from "../Target Industries/TargetIndustries";
// import MilitaryBackground from "../Military Background/MilitaryBackground";
import UploadFiles from "../Upload Files/UploadFiles";
import FileList from "../File Lists/FileList";
import SubmitToCompanyForm from "../SubmitToCompany/SubmitToCompany";
import CompanyVerification from "../CompanyVerification/CompanyVerification";
import Pipeline from "../PipeLine/Pipeline";
import Jobs from "../CompanyVerification/Jobs/Jobs";
import AboutCompany from "../AboutCompany/AboutCompany";
import CompanyInfo from "../Company Info/CompanyInfo";
import UserLists from "../Users/UserList";
// import UploadOriginalResume from "../Resume/UploadResume/UploadOriginalResume";
// import UploadResumeFile from "../Resume/UploadResume/UploadResumeFile";
// import ResumeList from "../ResumeList/ResumeList";
// import OriginalResume from "../Resume/OriginalResume";

const CompanyDetailPopup = ({ company: initialCandidate, onClose, admin }) => {
  const [company, setCompany] = useState(initialCandidate);
  const [activeTab, setActiveTab] = useState("email");
  const [activeSection, setActiveSection] = useState("activity");
  const [isSubmitCompanyModalOpen, setIsSubmitCompanyModalOpen] =
    useState(false);
  if (!company) return null;

  const toggleSubmitForm = () => {
    setIsSubmitCompanyModalOpen(!isSubmitCompanyModalOpen);
  };

  const handleCandidateUpdate = (updatedCompany) => {
    setCompany(updatedCompany); // Update candidate state dynamically
  };

  return (
    <>
      <div className="overlay"></div>
      <div className={`candidate-details-popup ${company ? "show" : ""}`}>
        <div className="popup-content">
          <button className="close-button" onClick={onClose}>
            <img src={closeIcon} alt="close-icon" style={{ width: "30px" }} />
          </button>

          <div className="row profile_row">
            <div className="col-lg-4 col-md-4 col-12 left_column">
              <div className="popup_left_profile">
                <CompanyInfo
                  company={company}
                  toggleSubmitForm={toggleSubmitForm}
                />

                <CompanyVerification company={company} />

                <Pipeline company={company} />

                <Jobs />
                <AboutCompany />

                <UserLists />

                {/* <JobSearchStatus
                  candidate={candidate}
                  onUpdate={handleCandidateUpdate} // Pass update handler
                />
                <OriginalResume candidate={candidate} />
                <Resume candidate={candidate} />
                <AboutCandidate candidate={candidate} />
                <Certification candidate={candidate} />
                <TargetIndustries candidate={candidate} />
                <MilitaryBackground candidate={candidate} /> */}
              </div>
            </div>

            <div className="col-lg-8 col-md-8 col-12">
              <div className="popup_right_profile">
                {isSubmitCompanyModalOpen && (
                  <SubmitToCompanyForm company={company} />
                )}

                {!isSubmitCompanyModalOpen && (
                  <>
                    {/* Tab Buttons */}
                    <div className="tab-buttons">
                      <button
                        className={activeTab === "email" ? "active" : ""}
                        onClick={() => setActiveTab("email")}
                      >
                        Email
                      </button>
                      <button
                        className={activeTab === "notes" ? "active" : ""}
                        onClick={() => setActiveTab("notes")}
                      >
                        Notes
                      </button>
                    </div>

                    {/* Render components based on the active tab */}
                    {activeTab === "email" && <EmailEditor company={company} />}
                    {activeTab === "notes" && <NotesEditor company={company} />}

                    {/* Section Buttons */}
                    <div className="recent-section-buttons">
                      <button
                        className={activeSection === "activity" ? "active" : ""}
                        onClick={() => setActiveSection("activity")}
                      >
                        Recent Activity
                      </button>
                      <button
                        className={activeSection === "jobs" ? "active" : ""}
                        onClick={() => setActiveSection("jobs")}
                      >
                        Companies & Jobs
                      </button>

                      <button
                        className={
                          activeSection === "interviews" ? "active" : ""
                        }
                        onClick={() => setActiveSection("interviews")}
                      >
                        Interviews
                      </button>

                      <button
                        className={activeSection === "files" ? "active" : ""}
                        onClick={() => setActiveSection("files")}
                      >
                        Files
                      </button>

                      <button
                        className={activeSection === "notes" ? "active" : ""}
                        onClick={() => setActiveSection("notes")}
                      >
                        Notes
                      </button>
                    </div>

                    {/* Render components based on the active section */}
                    {activeSection === "activity" && (
                      <RecentActivites company={company} />
                    )}
                    {activeSection === "jobs" && (
                      <CompaniesJobs company={company} />
                    )}

                    {activeSection === "notes" && (
                      <NotesList company={company} admin={admin} />
                    )}

                    {activeSection === "files" && (
                      <>
                        <div className="offer_letter_section">
                          <h6>Upload supporting documents</h6>
                          <UploadFiles company={company} />
                          <FileList company={company} />
                        </div>

                        {/* <div className="upload_resume_section">
                          <h6>Resume File</h6>
                          <ResumeList candidate={candidate} />
                        </div> */}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyDetailPopup;

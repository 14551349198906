import React, { useState } from "react";
import "./CompaniesJobsListing.css";
import trashIcon from "../../../../../assets/imgs/trash_icon.png";
import discolifiedIcon from "../../../../../assets/imgs/discolified.png";

const CompanyJobsListing = () => {
  const [companies, setCompanies] = useState([
    {
      id: 1,
      name: "Adobe",
      job: "Network Engineer",
      stage: "Hired",
      logo: "https://cdn-icons-png.flaticon.com/512/5436/5436922.png",
    },
    {
      id: 2,
      name: "Coca-Cola",
      job: "Brand Strategist",
      stage: "Sent Offer",
      logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQUGfQ0xx3Iv9yuxFCjyfe4uME8NRCMuKHgA&s",
    },
    // Add more data as needed
  ]);

  const [searchTerm, setSearchTerm] = useState("");
  const [sortStage, setSortStage] = useState("All");
  const [showOption, setShowOption] = useState("All");

  const stages = [
    "Hired",
    "Sent Offer",
    "Interviewed",
    "Applied",
    "Disqualified",
    "Company Submission",
    "Add to Favorites",
  ];

  // Handle stage change in dropdown
  const handleStageChange = (id, newStage) => {
    const updatedCompanies = companies.map((company) =>
      company.id === id ? { ...company, stage: newStage } : company
    );
    setCompanies(updatedCompanies);
  };

  // Filter and sort companies based on search, stage, and show options
  const filteredCompanies = companies.filter((company) => {
    const matchesSearch =
      company.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      company.job.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesStage = sortStage === "All" || company.stage === sortStage;

    const matchesShowOption =
      showOption === "All" || company.stage === showOption;

    return matchesSearch && matchesStage && matchesShowOption;
  });

  return (
    <div className="company-listing">
      {/* Search input */}
      <div className="search_functionality">
        <input
          type="text"
          placeholder="Search any keyword "
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-box"
        />

        {/* Sort by and Show options */}
        <div className="filter-options">
          <select
            value={sortStage}
            onChange={(e) => setSortStage(e.target.value)}
            className="sort-dropdown"
          >
            <option value="All">
              <span>Sort By:</span> Stage
            </option>
            {stages.map((stage) => (
              <option key={stage} value={stage}>
                {stage}
              </option>
            ))}
          </select>

          <select
            value={showOption}
            onChange={(e) => setShowOption(e.target.value)}
            className="show-dropdown"
          >
            <option value="All">Show: All</option>
            <option value="Hired">Hired</option>
            <option value="Sent Offer">Sent Offer</option>
            <option value="Interviewed">Interviewed</option>
            <option value="Applied">Applied</option>
            <option value="Disqualified">Disqualified</option>
            <option value="Company Submission">Company Submission</option>
            <option value="Add to Favorites">Add to Favorites</option>
          </select>
        </div>
      </div>
      {/* Display filtered companies */}
      <div className="company-cards">
        {filteredCompanies.map((company) => (
          <div key={company.id} className="company-card">
            <div>
              <div className="company_profile">
                <div className="company_logo">
                  <img
                    src={company.logo}
                    style={{ width: "40px ", borderRadius: "50%" }}
                  />
                </div>
                <div className="company_info">
                  <h3>{company.name}</h3>
                  <p>{company.job}</p>
                </div>
              </div>

              <div className="select_discolified_button">
                <select
                  value={company.stage}
                  onChange={(e) =>
                    handleStageChange(company.id, e.target.value)
                  }
                  className={`stage-dropdown ${company.stage.toLowerCase()}`}
                >
                  {stages.map((stage) => (
                    <option key={stage} value={stage}>
                      {stage}
                    </option>
                  ))}
                </select>

                <div
                  className="discolified_icon"
                  onClick={() => handleStageChange(company.id, "Disqualified")}
                  style={{ cursor: "pointer" }}
                >
                  <img src={discolifiedIcon} alt="Disqualify" />
                </div>
              </div>
            </div>

            <div>
              <img src={trashIcon} alt="trashIcon" className="trash_icon" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompanyJobsListing;

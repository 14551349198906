// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.job_section{
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    margin-top: 10px;
}

.job_section .job_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.job_header button{
    border: 1px solid #034A8C;
    background-color: transparent;
    color: #034A8C;
    padding: 7px 10px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 600;
}
.job_section .job_header h4{
    font-size: 14px;
  
}

.job_lists{
    padding-top: 15px;
}
.job_list{
    padding: 10px;
    border: 1px solid #CACFD3;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 10px;
}

.job_list h5{
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #034A8C;

}

.job_list .job_detail{
    display: flex;
    gap:20px;
    font-size: 12px;
}

.show_more button{
    border: none;
    background-color: transparent;
    color: #034A8C;
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/admin/dashboard/Companies/CompanyVerification/Jobs/Jobs.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,6BAA6B;IAC7B,cAAc;IACd,iBAAiB;IACjB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,eAAe;;AAEnB;;AAEA;IACI,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,eAAe;IACf,cAAc;;AAElB;;AAEA;IACI,aAAa;IACb,QAAQ;IACR,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,6BAA6B;IAC7B,cAAc;IACd,kBAAkB;IAClB,cAAc;IACd,aAAa;IACb,uBAAuB;IACvB,eAAe;AACnB","sourcesContent":[".job_section{\n    padding: 20px;\n    border-radius: 10px;\n    background-color: white;\n    margin-top: 10px;\n}\n\n.job_section .job_header{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.job_header button{\n    border: 1px solid #034A8C;\n    background-color: transparent;\n    color: #034A8C;\n    padding: 7px 10px;\n    border-radius: 10px;\n    font-size: 12px;\n    font-weight: 600;\n}\n.job_section .job_header h4{\n    font-size: 14px;\n  \n}\n\n.job_lists{\n    padding-top: 15px;\n}\n.job_list{\n    padding: 10px;\n    border: 1px solid #CACFD3;\n    margin-bottom: 10px;\n    cursor: pointer;\n    border-radius: 10px;\n}\n\n.job_list h5{\n    display: flex;\n    justify-content: space-between;\n    font-size: 14px;\n    color: #034A8C;\n\n}\n\n.job_list .job_detail{\n    display: flex;\n    gap:20px;\n    font-size: 12px;\n}\n\n.show_more button{\n    border: none;\n    background-color: transparent;\n    color: #034A8C;\n    text-align: center;\n    margin: 0 auto;\n    display: flex;\n    justify-content: center;\n    font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

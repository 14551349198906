import React, { useState, useEffect } from "react";
import axios from "axios";
import logo from "../../../assets/imgs/logo-7eagle.png";
import CandidateTable from "../../../components/admin/dashboard/Candidates/Candidate Records Table/CandidateTable";
import CandidateSearch from "../../../components/admin/dashboard/Candidates/Search Filters/CandidateSearch";
import ColumnEditModal from "../../../components/admin/dashboard/Candidates/Candidate Records Table/ColumnEditModal";
import Header from "../../../components/admin/dashboard/Header/Header";
import Sidebar from "../../../components/admin/dashboard/Sidebar/Sidebar";
import "../AdminDashboard.css";
import collapsedIcon from "../../../assets/imgs/collapse.png";
import AddFilterModal from "../../../components/admin/dashboard/Candidates/Add Filters/AddNewFilter";

import TextSearchFilter from "../../../components/admin/dashboard/Candidates/Add Filters/TextSearchFilter";
import MilitaryBranchFilter from "../../../components/admin/dashboard/Candidates/Add Filters/MilitaryBranchFilter";
import JobSearchStatusFilter from "../../../components/admin/dashboard/Candidates/Add Filters/JobSearchStatusFilter";
import RelocationFilter from "../../../components/admin/dashboard/Candidates/Add Filters/RelocationFilter";
import SecurityClearanceFilter from "../../../components/admin/dashboard/Candidates/Add Filters/SecurityClearanceFilter";

import ComapnyListTable from "../../../components/admin/dashboard/Companies/Company List/CompanyListTable";
import CompanySearch from "../../../components/admin/dashboard/Companies/SearchFilters/CompanySearchFilter";
import CompanyGridList from "../../../components/admin/dashboard/Companies/Company List/CompanyGridList";
import gridIcon from "../../../assets/imgs/gridIcon.png";
import listIcon from "../../../assets/imgs/listIcon.png";
import filterIcon from "../../../assets/imgs/filterIcon.png";
import filterIconWhite from "../../../assets/imgs/filterIconWhite.png";

import "./CompanyProfilePage.css";
import CompanyManagerFilter from "../../../components/admin/dashboard/Companies/SearchFilters/CompanyManagerFilter";
import CompanyNameFilter from "../../../components/admin/dashboard/Companies/SearchFilters/CompanyNameFilter";
import CurrentPlanFilter from "../../../components/admin/dashboard/Companies/SearchFilters/CurrentPlanFilter";
const CompanyProfilePage = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const [admin, setAdmin] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [companyData, setcompanyData] = useState([]); // Initialize candidate data as empty array
  const [filteredData, setFilteredData] = useState([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isFilterDivModalOpen, setIsFilterDivModalOpen] = useState(false);
  const [filterType, setFilterType] = useState("is one of"); // Define filterType in parent

  const [isManagerModalOpen, setIsManagerModalOpen] = useState(false);
  const [isTextSearchModalOpen, setIsTextSearchModalOpen] = useState(false);
  const [isRelocationModalOpen, setIsRelocationModalOpen] = useState(false);
  const [isGridView, setIsGridView] = useState(false);
  const [isCertificationModalOpen, setIsCertificationModalOpen] =
    useState(false);

  const [isSecurityClearanceModalOpen, setIsSecurityClearanceModalOpen] =
    useState(false);

  const [isJobStatusModalOpen, setIsJobStatusModalOpen] = useState(false);

  const [isCompanyNameModalOpen, setIsCompanyNameModalOpen] = useState(false);
  const [isCurrentFilterModalOpen, setIsCurrentFilterModalOpen] =
    useState(false);

  const [isMilitaryBranchModalOpen, setMilitaryBranchModalOpen] =
    useState(false);

  const [selectedManager, setSelectedManager] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [selectedCompanyName, setSelectedCompanyName] = useState(null);
  const [selectedCurrentPlan, setselectedCurrentPlan] = useState(null);

  const [selectedMilitaryBranch, setSelectedMilitaryBranch] = useState(null);
  const [selectedJobStatus, setSelectedJobStatus] = useState(null);
  const [selectedRelocation, setSelectedRelocation] = useState(null);
  const [selectedCertification, setSelectedCertification] = useState(null);

  const [selectedSecurityClearance, setSelectedSecurityClearance] =
    useState(null);
  const handleSelectedCertification = (selectedCertifications) => {
    setSelectedCertification(selectedCertifications); // Save the selected certifications

    const filtered = companyData.filter((candidate) => {
      // Ensure candidate.certifications is an array and check if any of the selected certifications are in the candidate's certifications array
      return selectedCertifications.every((selectedCert) =>
        candidate.certifications?.some(
          (cert) =>
            cert &&
            String(cert).toLowerCase() === String(selectedCert).toLowerCase()
        )
      );
    });

    setFilteredData(filtered); // Update filtered data based on selected certifications
  };

  // Function to toggle to Grid View
  const showGridView = () => {
    setIsGridView(true); // Set to grid view
  };

  // Function to toggle to Table View
  const showTableView = () => {
    setIsGridView(false); // Set to table view
  };

  const handleManagerSelect = (managerName) => {
    setSelectedManager(managerName);

    // Perform filtering based on the selected filter type
    const filtered = companyData.filter((company) => {
      if (filterType === "is one of") {
        // Exact match
        return (
          company.manager[0].name &&
          company.manager[0].name.toLowerCase() === managerName.toLowerCase()
        );
      } else if (filterType === "is not one of") {
        // Exact non-match
        return (
          company.manager[0].name &&
          company.manager[0].name.toLowerCase() !== managerName.toLowerCase()
        );
      } else if (filterType === "is set") {
        // Check if the manager name is not empty or null
        return company.manager[0].name && company.manager[0].name.trim() !== "";
      } else if (filterType === "is not set") {
        // Check if the manager name is empty or null
        return (
          !company.manager[0].name || company.manager[0].name.trim() === ""
        );
      } else {
        // Default case for "contains"
        return (
          company.manager[0].name &&
          company.manager[0].name
            .toLowerCase()
            .includes(managerName.toLowerCase())
        );
      }
    });

    setFilteredData(filtered);
  };
  // Handle Text Search
  const handleTextSearch = (selectedCompany) => {
    // Ensure selectedCompany is a string or extract the field you want
    const searchTerm =
      typeof selectedCompany === "string"
        ? selectedCompany
        : selectedCompany.name || "";

    setSelectedCandidate(selectedCompany);

    // Dynamic search: Partial match on any field
    const filtered = companyData.filter((company) =>
      Object.values(company).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

    setFilteredData(filtered);
  };

  const handleCompanyNameFilter = (companyName) => {
    setSelectedCompanyName(companyName);

    // Perform filtering based on the selected filter type
    const filtered = companyData.filter((company) => {
      if (filterType === "is one of") {
        // Exact match
        return (
          company.name &&
          company.name.toLowerCase() === companyName.toLowerCase()
        );
      } else if (filterType === "is not one of") {
        // Exact non-match
        return (
          company.name &&
          company.name.toLowerCase() !== companyName.toLowerCase()
        );
      } else if (filterType === "is set") {
        // Check if the manager name is not empty or null
        return company.name && company.name.trim() !== "";
      } else if (filterType === "is not set") {
        // Check if the manager name is empty or null
        return !company.name || company.name.trim() === "";
      } else {
        // Default case for "contains"
        return (
          company.name &&
          company.name.toLowerCase().includes(companyName.toLowerCase())
        );
      }
    });

    setFilteredData(filtered);
  };

  const handleCurrentPlanFilter = (currentFilter) => {
    setselectedCurrentPlan(currentFilter);

    // Perform filtering based on the selected filter type
    const filtered = companyData.filter((company) => {
      if (filterType === "is one of") {
        // Exact match
        return (
          company.userType &&
          company.userType.toLowerCase() === currentFilter.toLowerCase()
        );
      } else if (filterType === "is not one of") {
        // Exact non-match
        return (
          company.userType &&
          company.userType.toLowerCase() !== currentFilter.toLowerCase()
        );
      } else if (filterType === "is set") {
        // Check if the manager name is not empty or null
        return company.userType && company.userType.trim() !== "";
      } else if (filterType === "is not set") {
        // Check if the manager name is empty or null
        return !company.userType || company.userType.trim() === "";
      } else {
        // Default case for "contains"
        return (
          company.userType &&
          company.userType.toLowerCase().includes(currentFilter.toLowerCase())
        );
      }
    });

    setFilteredData(filtered);
  };

  const handleSecurityClearance = (securityClearance) => {
    console.log("Selected security clearance:", securityClearance); // Debugging line

    // If the securityClearance is an object, extract the string value
    const securityClearanceString =
      typeof securityClearance === "object" &&
      securityClearance.securityClearance
        ? securityClearance.securityClearance
        : securityClearance;

    setSelectedSecurityClearance(securityClearanceString);

    // Ensure securityClearance is a string, fallback to empty string if it's not
    const securityClearanceName =
      typeof securityClearanceString === "string"
        ? securityClearanceString.trim().toLowerCase()
        : "";

    // Filter candidates based on security clearance (both trimmed and lowercased)
    const filtered = companyData.filter((candidate) => {
      const candidateSecurityClearance =
        typeof candidate.securityClearance === "string"
          ? candidate.securityClearance.trim().toLowerCase()
          : ""; // Fallback to empty string if not a string
      return candidateSecurityClearance === securityClearanceName;
    });

    // Log the filtered data for debugging
    console.log("Filtered data:", filtered);

    // Update the filteredData state
    setFilteredData(filtered);
  };

  const handleSelectRelocation = (relocation) => {
    console.log("Selected relocation:", relocation); // Debugging line

    // If the relocation is an object, extract the string value
    const relocationString =
      typeof relocation === "object" && relocation.relocation
        ? relocation.relocation
        : relocation;

    setSelectedRelocation(relocationString);

    // Ensure relocation is a string, fallback to empty string if it's not
    const relocationName =
      typeof relocationString === "string"
        ? relocationString.trim().toLowerCase()
        : "";

    // Filter candidates based on relocation preference (both trimmed and lowercased)
    const filtered = companyData.filter((candidate) => {
      const candidateRelocation =
        typeof candidate.relocationprefrence === "string"
          ? candidate.relocationprefrence.trim().toLowerCase()
          : ""; // Fallback to empty string if not a string
      return candidateRelocation === relocationName;
    });

    // Log the filtered data for debugging
    console.log("Filtered data:", filtered);

    // Update the filteredData state
    setFilteredData(filtered);
  };

  const handleSelectStatus = (jobstatus) => {
    setSelectedJobStatus(jobstatus);

    console.log("Filtering candidates with job status:", jobstatus);

    // If jobstatus is an object, extract the correct string property (e.g., 'name' or 'statusName')
    const jobstatusName =
      jobstatus && typeof jobstatus === "object"
        ? jobstatus.statusName
        : jobstatus;

    const filtered = companyData.filter((candidate) => {
      const statusName = candidate.jobSearchStatusName || "";
      console.log(
        `Candidate job status: ${statusName}, Filter status: ${jobstatusName}`
      );
      return statusName === jobstatusName;
    });

    setFilteredData(filtered);
  };

  const handleSelectBranch = (branch) => {
    setSelectedMilitaryBranch(branch);
    const filtered = companyData.filter(
      (candidate) => candidate.branch && candidate.branch === branch
    );
    setFilteredData(filtered);
  };

  const resetTableData = () => {
    setFilteredData(companyData); // Reset to original candidate data
  };

  const handleToggleSidebar = (isCollapsed) => {
    setIsSidebarCollapsed(isCollapsed);
  };

  const [columns, setColumns] = useState([
    { id: 1, name: "Company Name", field: "name", visible: true },
    { id: 2, name: "Currunt Plan", field: "userType", visible: true },
    { id: 3, name: "Manager", field: "manager", visible: true },
    { id: 4, name: "Refered By", field: "referredBy", visible: true }, // `type` field mapped here
    { id: 5, name: "Recent Activity", field: "recentActivity", visible: true },
    { id: 6, name: "Applicants", field: "applicants", visible: true },
    {
      id: 7,
      name: "Hired Candidates",
      field: "hiredCandidates",
      visible: true,
    },
    {
      id: 8,
      name: "Interested Candidates",
      field: "interestedCandidate",
      visible: true,
    },

    { id: 9, name: "Interview", field: "interview", visible: true },
    { id: 10, name: "Sent Offer", field: "sentOffer", visible: true },
    {
      id: 11,
      name: "Submitted Candidates",
      field: "submittedCandidates",
      visible: true,
    },

    {
      id: 12,
      name: "Interested Candidates",
      field: "interestedcandidates",
      visible: true,
    },
    {
      id: 13,
      name: "Favorite Candidates",
      field: "favoriteCandidates",
      visible: true,
    },
    { id: 14, name: "Affiliate", field: "affiliate", visible: true },

    { id: 15, name: "Hot Candidate", field: "hotCandidate", visible: true },
  ]);

  // Select Filter Section
  const handleFilterClick = (filter) => {
    if (filter === "Manager") {
      setIsManagerModalOpen(true);
    }

    if (filter === "Text Search") {
      setIsTextSearchModalOpen(true);
    }

    if (filter === "Company Name") {
      setIsCompanyNameModalOpen(true);
    }

    if (filter === "Current Plan") {
      setIsCurrentFilterModalOpen(true);
    }

    if (filter === "Job Search Status") {
      setIsJobStatusModalOpen(true);
    }

    if (filter === "Relocation Option") {
      setIsRelocationModalOpen(true);
    }

    if (filter === "Security Clearance") {
      setIsSecurityClearanceModalOpen(true);
    }

    if (filter === "Certifications") {
      setIsCertificationModalOpen(true);
    } else {
      console.log(`${filter} filter clicked!`);
    }
  };
  const toggleColumnVisibility = (id) => {
    setColumns(
      columns.map((col) =>
        col.id === id ? { ...col, visible: !col.visible } : col
      )
    );
  };

  const onColumnReorder = (newColumns) => {
    setColumns(newColumns);
  };

  // Fetch candidate data from API
  useEffect(() => {
    const fetchcompanyData = async () => {
      try {
        const response = await axios.get(`/api/admin/clients`);
        setcompanyData(response.data); // Set fetched data to companyData
        setFilteredData(response.data); // Initialize filteredData with the fetched data
      } catch (error) {
        console.error("Error fetching candidate data:", error);
      }
    };

    fetchcompanyData();
  }, []);

  // Filter candidate data based on search term
  useEffect(() => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const filtered = companyData.filter((candidate) =>
      Object.values(candidate).some(
        (value) => String(value).toLowerCase().includes(lowerCaseSearchTerm) // Safely convert to string
      )
    );
    setFilteredData(filtered);
  }, [searchTerm, companyData]);

  useEffect(() => {
    const fetchAdmin = async () => {
      try {
        setLoading(true);
        const userId = "672718ceb54a7fb7e3149dc9";
        const response = await axios.get(`/api/admin/${userId}`);

        setAdmin(response.data);
      } catch (error) {
        console.log(
          "Error fetching Admin:",
          error.response ? error.response.data : error.message
        );
      } finally {
        setLoading(false);
      }
    };

    fetchAdmin();
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <img src={logo} className="logo-pre-loader" alt="Loading logo" />
        <div className="spinner"></div>
        <div>
          <h6>Please Wait..</h6>
        </div>
      </div>
    );
  }

  return (
    <div className="dashboard">
      <Header admin={admin} />
      <div className="row dashboardrow" style={{ minHeight: "100vh" }}>
        <div
          className={`col-lg-2 col-md-2 col-sm-4 col-4 sidebar ${
            isSidebarCollapsed ? "sidebarCollapsed" : ""
          }`}
        >
          <Sidebar onToggleSidebar={handleToggleSidebar} admin={admin} />
        </div>
        <div
          className={`col-lg-10 col-md-10 col-sm-8 col-8 sidebar-column contentbar ${
            isSidebarCollapsed ? "contentExpanded" : ""
          }`}
        >
          <main>
            <div className="main_heading_dashboard">
              <div className="left_content">
                <h4 style={{ color: "black" }}>Companies</h4>
                <span>{filteredData.length} Companies</span>
              </div>

              <div className="right_content">
                <button>Add New Company + </button>
              </div>
            </div>

            <div className="search_filter_section">
              <div className="row">
                <div className="col-lg-7 col-md-8">
                  <div className="search_bar">
                    <CompanySearch onSearch={setSearchTerm} />

                    <div className="toggle-btns">
                      <button onClick={showTableView}>
                        <img src={listIcon} alt="listIcon" />
                      </button>
                      <button onClick={showGridView}>
                        <img src={gridIcon} alt="gridIcon" />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-lg-5 col-md-4">
                  <div className="select_options_filter">
                    <button
                      className="add_new_filter_company"
                      onClick={() => setIsFilterModalOpen(true)}
                    >
                      <img
                        src={filterIcon}
                        alt="filterIcon"
                        className="filterIcon"
                      />
                      Add Filter
                    </button>

                    <button
                      className="edit_filter_company"
                      onClick={() => setIsFilterModalOpen(true)}
                    >
                      <img
                        src={filterIconWhite}
                        alt="filterIconWhite"
                        className="filterIcon"
                        style={{ width: "16px" }}
                      />
                      Edit Filters (3)
                    </button>
                    <span style={{ fontSize: "12px", color: "#212529" }}>
                      {" "}
                      Sort By:
                    </span>
                    <select className="recently-added">
                      <option>Recently Added</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="candidate_table_records">
                    {isGridView ? (
                      <CompanyGridList
                        columns={columns}
                        data={filteredData}
                        admin={admin}
                      />
                    ) : (
                      <ComapnyListTable
                        columns={columns}
                        data={filteredData}
                        admin={admin}
                      />
                    )}
                  </div>
                </div>

                <div
                  className={`main-box-filter ${
                    isFilterModalOpen ? "show" : "hide"
                  }`}
                >
                  <div className="mainbox-filter-header">
                    <h6>
                      <img
                        src={filterIcon}
                        alt="filerIcon"
                        className="filterIcon"
                      />
                      Company Filter
                    </h6>

                    <div className="clearFilter">
                      <img
                        src={filterIcon}
                        alt="filerIcon"
                        className="filterIcon"
                      />

                      <h5>Clear Filter</h5>
                      <span
                        onClick={() => setIsFilterModalOpen(false)}
                        style={{ cursor: "pointer" }}
                      >
                        X
                      </span>
                    </div>
                  </div>
                  <AddFilterModal
                    isOpen={isFilterModalOpen}
                    onClose={() => setIsFilterModalOpen(true)}
                    onFilterClick={handleFilterClick}
                  />

                  <CompanyManagerFilter
                    isOpen={isManagerModalOpen}
                    onClose={() => setIsManagerModalOpen(false)}
                    companyData={companyData} // Pass candidate data here
                    onSelectManager={handleManagerSelect} // Pass the handler
                    filterType={filterType} // Pass filterType as a prop to the child
                    setFilterType={setFilterType} // Pass setFilterType to update filterType
                    onResetFilters={resetTableData}
                  />

                  <TextSearchFilter
                    isOpen={isTextSearchModalOpen}
                    onClose={() => setIsTextSearchModalOpen(false)}
                    companyData={companyData}
                    onSelectcompany={handleTextSearch}
                  />

                  <CompanyNameFilter
                    isOpen={isCompanyNameModalOpen}
                    onClose={() => setIsCompanyNameModalOpen(false)}
                    filterType={filterType} // Pass filterType as a prop to the child
                    setFilterType={setFilterType}
                    companyData={companyData}
                    onSelectCompanyName={handleCompanyNameFilter}
                  />

                  <CurrentPlanFilter
                    isOpen={isCurrentFilterModalOpen}
                    onClose={() => setIsCurrentFilterModalOpen(false)}
                    filterType={filterType} // Pass filterType as a prop to the child
                    setFilterType={setFilterType}
                    companyData={companyData}
                    onSelectCurrentplan={handleCurrentPlanFilter}
                  />

                  <MilitaryBranchFilter
                    isOpen={isMilitaryBranchModalOpen}
                    onClose={() => setMilitaryBranchModalOpen(false)}
                    companyData={companyData}
                    onSelectBranch={handleSelectBranch}
                  />

                  <JobSearchStatusFilter
                    isOpen={isJobStatusModalOpen}
                    onClose={() => setIsJobStatusModalOpen(false)}
                    companyData={companyData}
                    onSelectStatus={handleSelectStatus}
                  />

                  <RelocationFilter
                    isOpen={isRelocationModalOpen}
                    onClose={() => setIsRelocationModalOpen(false)}
                    companyData={companyData}
                    onSelectRelocationPreference={handleSelectRelocation}
                  />

                  <SecurityClearanceFilter
                    isOpen={isSecurityClearanceModalOpen}
                    onClose={() => setIsSecurityClearanceModalOpen(false)}
                    companyData={companyData}
                    onSelectSecurityClearance={handleSecurityClearance}
                  />
                </div>
              </div>
            </div>

            {/* Button to open modal */}

            <ColumnEditModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              columns={columns}
              toggleColumnVisibility={toggleColumnVisibility}
              onColumnReorder={onColumnReorder}
            />
          </main>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfilePage;

import React, { useState, useEffect, useRef } from "react";
import "./SecurityClearanceFilter.css"; // Ensure you have a separate CSS file for security clearance filter
import iconUser from "../../../../../assets/imgs/icon-job-fill.png";
import editIcon from "../../../../../assets/imgs/Edit.png";
import delIcon from "../../../../../assets/imgs/trash.png";

const SecurityClearanceFilter = ({
  isOpen,
  onClose,
  candidateData,
  onSelectSecurityClearance,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSecurityClearance, setSelectedSecurityClearance] =
    useState(null);
  const [filterType, setFilterType] = useState("is one of");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const modalRef = useRef(null);

  // Filtering logic for securityClearance
  const filteredSecurityClearance =
    searchTerm.length >= 1
      ? candidateData.filter((candidate) => {
          const securityClearance = candidate.securityClearance
            ? candidate.securityClearance.toLowerCase()
            : ""; // Safeguard: Only call .toLowerCase() if securityClearance is defined
          const searchWords = searchTerm.trim().toLowerCase().split(/\s+/);

          switch (filterType) {
            case "is one of":
              return searchWords.every((word) =>
                securityClearance.includes(word)
              );
            case "is not one of":
              return searchWords.some(
                (word) => !securityClearance.includes(word)
              );
            case "is set":
              return securityClearance.length > 0;
            case "is not set":
              return securityClearance.length === 0;
            default:
              return false;
          }
        })
      : [];

  useEffect(() => {
    const handleOutsideClick = (event) => {};

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  const handleSelectSecurityClearance = (securityClearance) => {
    setSelectedSecurityClearance({ securityClearance });

    // Safeguard: Ensure securityClearance is a valid string or set it to an empty string
    const clearanceName = securityClearance ? securityClearance : "";
    setSearchTerm(clearanceName); // Only set the security clearance as the search term
  };

  const handleDone = () => {
    if (selectedSecurityClearance) {
      onSelectSecurityClearance(selectedSecurityClearance);
      setIsConfirmed(true);
    }
  };

  const handleEdit = () => {
    setIsConfirmed(false);
  };

  const handleDelete = () => {
    setSelectedSecurityClearance(null);
    setSearchTerm("");
    setIsConfirmed(false);
  };

  if (!isOpen) return null;

  return (
    <div className="security-clearance-filter-modal-main">
      <div className="security-clearance-filter-modal-content" ref={modalRef}>
        {isConfirmed ? (
          <div className="confirmation-view">
            <div className="selected_header">
              <div>
                <h6>
                  <img src={iconUser} alt="icon-user" className="icon-user" />
                  Security Clearance
                </h6>
              </div>
              <div className="manage-icons">
                <img
                  src={editIcon}
                  alt="edit-icon"
                  className="edit-Icon"
                  onClick={handleEdit}
                />
                <img
                  src={delIcon}
                  alt="del-icon"
                  className="deleteIcon"
                  onClick={handleDelete}
                />
              </div>
            </div>
            <p className="selected-clearance-name">
              {selectedSecurityClearance.securityClearance}
            </p>
          </div>
        ) : (
          <>
            <div className="filter-options">
              <h6>
                <img src={iconUser} alt="icon-user" className="icon-user" />
                Security Clearance
              </h6>
              <div className="clearance-list-radio-buttons">
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is one of"
                    checked={filterType === "is one of"}
                    onChange={(e) => setFilterType(e.target.value)}
                  />
                  Is one of
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is not one of"
                    checked={filterType === "is not one of"}
                    onChange={(e) => setFilterType(e.target.value)}
                  />
                  Is not one of
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is set"
                    checked={filterType === "is set"}
                    onChange={(e) => setFilterType(e.target.value)}
                  />
                  Is set
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is not set"
                    checked={filterType === "is not set"}
                    onChange={(e) => setFilterType(e.target.value)}
                  />
                  Is not set
                </label>
              </div>
            </div>
            <input
              type="text"
              placeholder="Search Security Clearance"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="clearance-search-bar"
            />
            <div className="clearance-list">
              {searchTerm.length >= 2 &&
              filteredSecurityClearance.length === 0 ? (
                <div className="no-results">No Match Result</div>
              ) : (
                filteredSecurityClearance.map((candidate, index) => (
                  <div
                    key={index}
                    className="clearance-item"
                    onClick={() =>
                      handleSelectSecurityClearance(candidate.securityClearance)
                    }
                  >
                    {candidate.securityClearance}
                  </div>
                ))
              )}
            </div>

            <div className="modal-footer">
              <button className="done-button" onClick={handleDone}>
                Done
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SecurityClearanceFilter;

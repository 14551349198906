import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Quill's styles
import "./TextEditor.css";

const TextEditor = ({ value, onChange }) => {
  return (
    <div>
      <ReactQuill
        value={value} // Value ko pass karein jo parent se aa raha hai
        onChange={onChange} // Change handle karne ke liye parent se function pass karein
        modules={{
          toolbar: [
            [{ header: "1" }, { header: "2" }, { font: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            ["bold", "italic", "underline"],
            ["link"],
            [{ align: [] }],
            ["image"],
          ],
        }}
      />
    </div>
  );
};

export default TextEditor;

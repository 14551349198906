import React, { useState } from "react";
import "./UserList.css";
import moreIcon from "../../../../../assets/imgs/moreIcon.png";
function UserLists() {
  // Sample job data, replace this with your actual job data
  const allUsers = [
    {
      name: "Jethro Jamero",
      email: "jethro@7eagle.com",
      phone: "(325) 650 - 2006",
      userType: "Admin",
      status: "Open",
    },
    {
      name: "Arsalan",
      email: "arsalan@7eagle.com",
      phone: "(325) 650 - 2006",
      userType: "User",
      status: "Open",
    },
    {
      name: "Tyler",
      email: "tyler@7eagle.com",
      phone: "(325) 650 - 2006",
      userType: "Admin",
      status: "Open",
    },
    {
      name: "Jordie",
      email: "jk@7eagle.com",
      phone: "(325) 650 - 2006",
      userType: "Admin",
      status: "Open",
    },
    {
      name: "Ben",
      email: "ben@7eagle.com",
      phone: "(325) 650 - 2006",
      userType: "User",
      status: "Open",
    },
    {
      name: "Claire",
      email: "claire@7eagle.com",
      phone: "(325) 650 - 2006",
      userType: "Admin",
      status: "Open",
    },
    {
      name: "Trish",
      email: "trish@7eagle.com",
      phone: "(325) 650 - 2006",
      userType: "User",
      status: "Open",
    },
  ];

  const [visibleUsers, setVisibleUsers] = useState(3); // Show 3 Users initially

  // Show next 3 Users
  const showMoreUsers = () => {
    setVisibleUsers(visibleUsers + 3);
  };

  return (
    <div className="users_section">
      <div className="users_header">
        <h4>Users</h4>
        <button>Add New Users</button>
      </div>

      <div className="users_lists">
        {allUsers.slice(0, visibleUsers).map((job, index) => (
          <div className="users_list" key={index}>
            <div className="userinfo">
              <h5>
                {job.name}
                <span className="userType">{job.userType}</span>
              </h5>

              <div className="more_icon">
                <img
                  src={moreIcon}
                  alt="moreIcon"
                  style={{ width: "3px", height: "auto" }}
                />
              </div>
            </div>

            <div className="users_detail">
              <span className="users_email">{job.email}</span>
              <span className="users_phone">{job.phone}</span>
            </div>
          </div>
        ))}
      </div>

      {/* Show the "More Users" button only if there are more Users to show */}
      {visibleUsers < allUsers.length && (
        <div className="show_more">
          <button onClick={showMoreUsers}>
            +{allUsers.length - visibleUsers} more Users
          </button>
        </div>
      )}
    </div>
  );
}

export default UserLists;

import React, { useState } from "react";
import "./EditAboutCompany.css"; // Create your own styling for this component

function EditAboutCompany() {
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [industryInput, setIndustryInput] = useState("");
  const [industries, setIndustries] = useState([]); // For storing added industries (tags)
  const [industrySuggestions, setIndustrySuggestions] = useState([
    "Cyber Security",
    "Software Development",
    "Finance",
    "Engineering",
  ]); // Sample suggestions
  const [companyDescription, setCompanyDescription] = useState("");

  const handleIndustryInput = (e) => {
    setIndustryInput(e.target.value); // Update the industry input field as user types
    console.log("Industry Input:", e.target.value); // Debug log
  };

  const handleAddIndustry = (e) => {
    if (e.key === "Enter" && industryInput.trim() !== "") {
      // Add the typed industry as a tag if the user presses Enter
      if (!industries.includes(industryInput.trim())) {
        setIndustries([...industries, industryInput.trim()]);
      }
      setIndustryInput(""); // Clear the input field after adding the tag
    }
  };

  const handleClickSuggestion = (suggestion) => {
    // Add the clicked suggestion as a tag
    if (!industries.includes(suggestion)) {
      setIndustries([...industries, suggestion]);
    }
    setIndustryInput(""); // Clear the input field after selecting a suggestion
  };

  const handleRemoveIndustry = (industry) => {
    // Remove a tag when the "X" is clicked
    setIndustries(industries.filter((item) => item !== industry));
  };

  const handleSave = () => {
    // Handle save logic (e.g., send data to API or save locally)
    console.log("Saved data:", {
      websiteUrl,
      industries,
      companyDescription,
    });
  };

  // Filter suggestions based on user input
  const filteredSuggestions = industrySuggestions.filter((suggestion) =>
    suggestion.toLowerCase().includes(industryInput.toLowerCase())
  );

  // Log filtered suggestions to verify the filtering logic
  console.log("Filtered Suggestions:", filteredSuggestions);

  return (
    <div className="edit-about-company">
      <div className="form-field">
        <label>Website URL</label>
        <input
          type="text"
          value={websiteUrl}
          onChange={(e) => setWebsiteUrl(e.target.value)}
          placeholder="Enter website URL"
        />
      </div>

      <div className="form-field">
        <label>Industry</label>
        <input
          type="text"
          value={industryInput}
          onChange={handleIndustryInput}
          onKeyDown={handleAddIndustry}
          placeholder="Type industry and press Enter"
        />
        {industryInput.length >= 2 && (
          <div className="suggestions">
            {/* Log filteredSuggestions to see if they match the input */}
            {filteredSuggestions.length > 0 ? (
              filteredSuggestions.map((suggestion, index) => (
                <div
                  key={index}
                  className="suggestion-item"
                  onClick={() => handleClickSuggestion(suggestion)}
                >
                  {suggestion}
                </div>
              ))
            ) : (
              <div className="no-suggestions">No suggestions available</div>
            )}
          </div>
        )}
        <div className="tags-container">
          {industries.map((industry, index) => (
            <div className="tag" key={index}>
              {industry}
              <span
                className="remove-tag"
                onClick={() => handleRemoveIndustry(industry)}
              >
                &times;
              </span>
            </div>
          ))}
        </div>
      </div>

      <div className="form-field">
        <label>Company Description</label>
        <textarea
          value={companyDescription}
          onChange={(e) => setCompanyDescription(e.target.value)}
          placeholder="Enter company description"
        />
      </div>

      <div className=" btn-saved">
        <button className="save-btn" onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
}

export default EditAboutCompany;

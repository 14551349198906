import React, { useState } from "react";
import Modal from "react-modal";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "./ProfileImagePopup.css";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function ProfileImagePopup({ isOpen, onRequestClose, onSaveImage }) {
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({
    unit: "px", // or "%" based on your preference
    width: 150,
    height: 150,
    aspect: 1,
    x: 0, // Initialize x
    y: 0, // Initialize y
  });
  const [imageRef, setImageRef] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [zoom, setZoom] = useState(1); // Initialize zoom state

  const onImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onImageLoaded = (image) => {
    setImageRef(image);
    setCrop({ ...crop, width: 30, height: 30 }); // Initialize crop size when image loads
    return false; // Image load hone par crop set na ho
  };

  const getCroppedImg = () => {
    if (
      imageRef &&
      crop.width &&
      crop.height &&
      crop.x !== undefined &&
      crop.y !== undefined
    ) {
      const canvas = document.createElement("canvas");
      const scaleX = imageRef.naturalWidth / imageRef.width / zoom; // Apply zoom factor
      const scaleY = imageRef.naturalHeight / imageRef.height / zoom; // Apply zoom factor
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");

      ctx.drawImage(
        imageRef,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      const croppedImageUrl = canvas.toDataURL("image/jpeg");
      setCroppedImageUrl(croppedImageUrl);
      return croppedImageUrl;
    }
    return null;
  };

  const handleSaveImage = () => {
    const croppedImage = getCroppedImg();
    if (croppedImage) {
      onSaveImage(croppedImage);
      onRequestClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Edit Profile Image"
    >
      <h2>Upload or Edit Your Photo</h2>
      <p>
        Apni clear aur professional photo upload karo jo companies ko dikhaye
      </p>

      <div>
        {image ? (
          <>
            <ReactCrop
              src={image}
              crop={crop}
              onImageLoaded={onImageLoaded}
              onChange={(newCrop) => setCrop(newCrop)} // Update crop state
            />
            <div style={{ marginTop: "10px" }}>
              <label htmlFor="zoom">Zoom: {zoom.toFixed(2)}x</label>
              <input
                id="zoom"
                type="range"
                min="1"
                max="3"
                step="0.1"
                value={zoom}
                onChange={(e) => setZoom(e.target.value)}
                style={{ width: "100%" }}
              />
            </div>
            {croppedImageUrl ? (
              <img
                alt="Cropped"
                style={{ maxWidth: "100%" }}
                src={croppedImageUrl}
              />
            ) : (
              <p>No cropped image available</p>
            )}
          </>
        ) : (
          <input type="file" accept="image/*" onChange={onImageChange} />
        )}
      </div>

      <button onClick={handleSaveImage}>Save Photo</button>
      <button onClick={onRequestClose}>Cancel</button>
    </Modal>
  );
}

export default ProfileImagePopup;

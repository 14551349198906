import React, { useState } from "react";
import "./Jobs.css";

function Jobs() {
  // Sample job data, replace this with your actual job data
  const allJobs = [
    {
      title: "Penetration Testing",
      type: "Hybrid",
      location: "Amherst, MA",
      salary: "$75,000 - 80,000",
      status: "Open",
    },
    {
      title: "Software Developer",
      type: "Remote",
      location: "New York, NY",
      salary: "$80,000 - 90,000",
      status: "Open",
    },
    {
      title: "Project Manager",
      type: "Hybrid",
      location: "Boston, MA",
      salary: "$90,000 - 100,000",
      status: "Open",
    },
    {
      title: "Data Scientist",
      type: "Remote",
      location: "San Francisco, CA",
      salary: "$100,000 - 110,000",
      status: "Open",
    },
    {
      title: "Frontend Developer",
      type: "Hybrid",
      location: "Austin, TX",
      salary: "$85,000 - 95,000",
      status: "Open",
    },
    {
      title: "Backend Developer",
      type: "Remote",
      location: "Chicago, IL",
      salary: "$95,000 - 105,000",
      status: "Open",
    },
    {
      title: "UX/UI Designer",
      type: "Hybrid",
      location: "Los Angeles, CA",
      salary: "$70,000 - 80,000",
      status: "Open",
    },
  ];

  const [visibleJobs, setVisibleJobs] = useState(3); // Show 3 jobs initially

  // Show next 3 jobs
  const showMoreJobs = () => {
    setVisibleJobs(visibleJobs + 3);
  };

  return (
    <div className="job_section">
      <div className="job_header">
        <h4>Jobs</h4>
        <button>Add New Job</button>
      </div>

      <div className="job_lists">
        {allJobs.slice(0, visibleJobs).map((job, index) => (
          <div className="job_list" key={index}>
            <h5>
              {job.title}
              <span>({job.status})</span>
            </h5>

            <div className="job_detail">
              <span className="job_type">{job.type}</span>
              <span className="job_location">{job.location}</span>
              <span className="salary">{job.salary}</span>
            </div>
          </div>
        ))}
      </div>

      {/* Show the "More Jobs" button only if there are more jobs to show */}
      {visibleJobs < allJobs.length && (
        <div className="show_more">
          <button onClick={showMoreJobs}>
            +{allJobs.length - visibleJobs} more jobs
          </button>
        </div>
      )}
    </div>
  );
}

export default Jobs;

import React, { useState, useEffect, useRef } from "react";
import "./TextSearchFilter.css";
import iconUser from "../../../../../assets/imgs/Search.png";
import editIcon from "../../../../../assets/imgs/Edit.png";
import delIcon from "../../../../../assets/imgs/trash.png";

const TextSearchFilter = ({
  isOpen,
  onClose,
  companyData,
  onSelectcompany,
}) => {
  const [searchTerm, setSearchTerm] = useState(""); // Search input from user
  const [originalSearchTerm, setOriginalSearchTerm] = useState(""); // Store original search term
  const [selectedcompany, setSelectedcompany] = useState(null); // Track selected company
  const [filterType, setFilterType] = useState("contains one of"); // Default filter type
  const [isConfirmed, setIsConfirmed] = useState(false); // State to toggle views
  const modalRef = useRef(null);

  const handleEdit = () => {
    setIsConfirmed(false); // Switch back to filter-options view
  };

  const handleDelete = () => {
    setSelectedcompany(null); // Clear selected company
    setSearchTerm(""); // Reset search term
    setOriginalSearchTerm(""); // Reset original search term
    setIsConfirmed(false); // Switch back to filter-options view
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose(); // Close modal if clicked outside
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  const handleSelectcompany = (company) => {
    setSelectedcompany(company); // Set the selected company
  };

  const handleDone = () => {
    if (searchTerm.trim()) {
      setOriginalSearchTerm(searchTerm); // Save the original search term
      setIsConfirmed(true); // Switch to confirmation view

      // Filter company data based on the search term
      const filteredCompanies = companyData.filter((company) =>
        Object.values(company).some((value) => {
          // Ensure value is a string before comparison
          if (typeof value === "string") {
            return value.toLowerCase().includes(searchTerm.toLowerCase());
          }
          return false; // Skip non-string values
        })
      );

      // Pass filtered data back to the parent component
      onSelectcompany(filteredCompanies);
    } else {
      console.error("Search term is empty.");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="text-search-modal-main">
      <div className="text-search-modal-content" ref={modalRef}>
        {isConfirmed ? (
          <div className="confirmation-view">
            <div className="selected_header">
              <div>
                <h6>
                  <img src={iconUser} alt="icon-user" className="icon-user" />
                  Text Search
                </h6>
              </div>

              <div className="manage-icons">
                <img
                  src={editIcon}
                  alt="edit-icon"
                  className="edit-Icon"
                  onClick={handleEdit}
                />
                <img
                  src={delIcon}
                  alt="del-icon"
                  className="deleteIcon"
                  onClick={handleDelete}
                />
              </div>
            </div>

            <p className="selected-manager-name">
              {filterType} {originalSearchTerm}
            </p>
          </div>
        ) : (
          <>
            <div className="filter-options">
              <h6>Text Search</h6>
              <div className="filter-type-dropdown">
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="contains one of"
                    checked={filterType === "contains one of"}
                    onChange={(e) => setFilterType(e.target.value)}
                  />
                  Contains one of
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="does not contain any of"
                    checked={filterType === "does not contain any of"}
                    onChange={(e) => setFilterType(e.target.value)}
                  />
                  Does not contain any of
                </label>
              </div>
            </div>
            <input
              type="text"
              placeholder="Search company"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="text-search-bar"
            />

            <div className="modal-footer">
              <button className="done-button" onClick={handleDone}>
                Done
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TextSearchFilter;

import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Ensure you import the CSS
import "./JobSearchStatus.css";

function UpdateJobSearchSlider({ candidate, onUpdate, onClose }) {
  const [formData, setFormData] = useState({
    ...candidate,
    jobSearchStatus: candidate.jobSearchStatus || "3",
    jobSearchStatusName:
      candidate.jobSearchStatusName ||
      "Casually Exploring – Browsing opportunities.",
    candidateType: candidate.type || "",
    idno: candidate.recruiterFlowId || "",
  });

  const values = [
    "Ready for a Move – Eager to make a change now.",
    "Seeking Opportunities – Actively searching for new roles.",
    "Casually Exploring – Browsing opportunities.",
    "Open to Offers – Open for the right fit.",
    "Happily Employed – Not looking.",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "jobSearchStatus") {
      setFormData({
        ...formData,
        [name]: Number(value),
        jobSearchStatusName: values[Number(value) - 1],
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(
        `${apiUrl}/api/admin/candidates/${candidate._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) throw new Error("Failed to update candidate");

      toast.success("Candidate updated successfully!");

      // Pass updated candidate data to parent using onUpdate callback
      if (onUpdate) {
        onUpdate(formData); // Send updated candidate to the parent component
      }

      setTimeout(() => {
        if (onClose) {
          onClose(); // This will close the form by changing the state in the parent
        }
      }, 2000); // Close the form after the update
    } catch (error) {
      toast.error("Error updating candidate: " + error.message);
    }
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />
      <form onSubmit={handleSubmit} className="update-job-search-form">
        <select
          name="jobSearchStatus"
          value={formData.jobSearchStatus}
          onChange={handleChange}
          style={{
            width: "100%",
            padding: "10px",
            borderRadius: "10px",
            marginTop: "20px",
            fontSize: "14px",
          }}
        >
          {values.map((status, index) => (
            <option key={index + 1} value={index + 1}>
              {status}
            </option>
          ))}
        </select>

        <button type="submit" className="btn-save-changes">
          Save
        </button>
      </form>
    </>
  );
}

export default UpdateJobSearchSlider;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search_filter input{
    padding: 12px 20px;
    border-radius: 10px;
    border: none;
    outline: 1px solid #DBDEE1;
    width: 50%;
}

.search_filter input:focus{
    border: none;
   outline:1px solid ;
}

.search_filter button{

    border: none;
    background-color: #ffc500;
    padding: 10px 20px !important;
    border-radius: 10px;
    color: #6c5202;
    font-size: 14px;

}

.search_filter input::placeholder{
    font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/admin/dashboard/Companies/SearchFilters/CompanySearchFilter.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,0BAA0B;IAC1B,UAAU;AACd;;AAEA;IACI,YAAY;GACb,kBAAkB;AACrB;;AAEA;;IAEI,YAAY;IACZ,yBAAyB;IACzB,6BAA6B;IAC7B,mBAAmB;IACnB,cAAc;IACd,eAAe;;AAEnB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".search_filter input{\n    padding: 12px 20px;\n    border-radius: 10px;\n    border: none;\n    outline: 1px solid #DBDEE1;\n    width: 50%;\n}\n\n.search_filter input:focus{\n    border: none;\n   outline:1px solid ;\n}\n\n.search_filter button{\n\n    border: none;\n    background-color: #ffc500;\n    padding: 10px 20px !important;\n    border-radius: 10px;\n    color: #6c5202;\n    font-size: 14px;\n\n}\n\n.search_filter input::placeholder{\n    font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

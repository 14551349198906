import React, { useState } from "react";
import "./CandidateSearch.css";
const CandidateSearch = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleButtonClick = () => {
    onSearch(searchTerm); // Trigger search when button is clicked
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onSearch(searchTerm); // Trigger search when "Enter" is pressed
    }
  };

  return (
    <div
      style={{ marginTop: "20px", display: "flex", gap: "0.5rem" }}
      className="search_filter"
    >
      <input
        type="text"
        placeholder="Enter any keyword (i.e, certifications, location)"
        value={searchTerm}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress} // Add key press event listener
      />
      <button onClick={handleButtonClick} style={{ padding: "0.5rem" }}>
        Search Candidates
      </button>
    </div>
  );
};

export default CandidateSearch;

import React, { useState, useEffect, useRef } from "react";
import "./CandidateInfo.css";
import moreIcon from "../../../../../assets/imgs/More.png";
import externalIcon from "../../../../../assets/imgs/external_link.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Ensure you import the CSS

import {
  MdOutlineEmail,
  MdOutlinePhone,
  MdOutlineLocationOn,
} from "react-icons/md";

import UpdateBasicInformation from "../Profile Information/UpdateBasicInformationPopup";

function CandidateInfo({ candidate, toggleSubmitForm }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedCandidate, setUpdatedCandidate] = useState(candidate);
  const [isManagerDropdownOpen, setIsManagerDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [managers, setManagers] = useState(["Jordie", "Jethro", "Tyler"]); // Sample list of managers
  const [selectedManagers, setSelectedManagers] = useState(
    candidate.managers || []
  );

  const dropdownRef = useRef(null); // Ref for main dropdown
  const managerDropdownRef = useRef(null); // Ref for manager dropdown

  // Calculate Progress Percentage
  const hasBasicInfo =
    candidate.fname &&
    candidate.lname &&
    candidate.phone &&
    candidate.city &&
    candidate.state;

  const hasMilitaryBackground =
    candidate.branch &&
    candidate.rank &&
    candidate.mos &&
    candidate.yearsServed;

  const hasCompleteProfileInfo =
    candidate.description &&
    candidate.securityClearance &&
    candidate.yearsOfExperience &&
    candidate.idealJobTitle &&
    candidate.relocationprefrence;

  const hasProfilePhoto = candidate.headshotLink;

  const totalSections = 4;
  let completedSections = 0;

  if (hasBasicInfo) completedSections++;
  if (hasMilitaryBackground) completedSections++;
  if (hasProfilePhoto) completedSections++;
  if (hasCompleteProfileInfo) completedSections++;

  const progress = (completedSections / totalSections) * 100;

  const toggleForm = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleUpdate = (updatedData) => {
    setUpdatedCandidate({
      ...updatedCandidate,
      fname: updatedData.fname,
      lname: updatedData.lname,
      email: updatedData.email,
      phone: updatedData.phone,
      idno: updatedData.idno,
      state: updatedData.state,
      city: updatedData.city,
      candidateType: updatedData.candidateType,
    });
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleManagerDropdown = () => {
    setIsManagerDropdownOpen(!isManagerDropdownOpen);
  };

  const handleManagerSelect = (manager) => {
    if (selectedManagers.includes(manager)) {
      setSelectedManagers(selectedManagers.filter((m) => m !== manager));
    } else if (selectedManagers.length < 2) {
      setSelectedManagers([...selectedManagers, manager]);
    } else {
      toast.error("You can select a maximum of 2 managers.");
    }
  };

  const handleSaveManagers = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    try {
      const response = await fetch(
        `${apiUrl}/api/admin/candidates/addManager/${candidate._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ managers: selectedManagers }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error:", errorData.message);
        toast.error("Error:", errorData.message);
      } else {
        toast.success("Manager Added Successful!");
      }
    } catch (error) {
      console.error("Error:", error.message);
      alert("An error occurred while updating managers.");
      toast.error("An error occurred while updating managers");
    }
  };

  const filteredManagers = managers.filter((manager) =>
    manager.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Close dropdowns on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isDropdownOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }

      if (
        isManagerDropdownOpen &&
        managerDropdownRef.current &&
        !managerDropdownRef.current.contains(event.target)
      ) {
        setIsManagerDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen, isManagerDropdownOpen]);

  return (
    <div>
      <ToastContainer />
      {!isModalOpen ? (
        <div className="profile_info_candidate_section">
          <div className="profile_visiblity">
            <div>
              <p>
                Profile Visibility: <b>Public</b>
              </p>
            </div>
            <div className="open_new_window">
              <a href="">
                Open in new Window{" "}
                <img
                  src={externalIcon}
                  alt="external_link"
                  style={{ width: "20px" }}
                />
              </a>
            </div>
          </div>

          <div className="profile_info_candidates">
            <div className="profile_info_detail">
              <img
                src={`${process.env.REACT_APP_API_URL}/${candidate.headshotLink}`}
                className="profile_img"
              />

              <div className="candidate_info_name">
                <h2>
                  {updatedCandidate.fname}{" "}
                  {updatedCandidate.lname || candidate.name}
                </h2>

                <h5>
                  ID
                  {updatedCandidate.idno || candidate.recruiterFlowId}
                </h5>
                <span>{updatedCandidate.candidateType || candidate.type}</span>
              </div>
            </div>

            <div className="more_icon" ref={dropdownRef}>
              <img src={moreIcon} className="arrow" onClick={toggleDropdown} />

              {isDropdownOpen && (
                <div className="dropdown-edit-profile">
                  <button onClick={toggleForm}>Edit Info</button>
                  <button onClick={toggleSubmitForm}>Present to Company</button>
                  <button>Add to Hot Candidates</button>
                  <button>Mark as hired</button>
                </div>
              )}
            </div>
          </div>

          <div className="profile_info_details">
            <div className="location_address">
              <p>
                <MdOutlineLocationOn
                  style={{
                    color: "#4A4D50",
                    fontSize: "18px",
                    marginRight: "10px",
                  }}
                />
                {updatedCandidate.city || candidate.city},{" "}
                {updatedCandidate.state || candidate.state}
              </p>
              <p>
                <MdOutlineEmail
                  style={{
                    color: "#4A4D50",
                    fontSize: "18px",
                    marginRight: "10px",
                  }}
                />
                {updatedCandidate.email || candidate.email}
              </p>
              <p>
                <MdOutlinePhone
                  style={{
                    color: "#4A4D50",
                    fontSize: "18px",
                    marginRight: "10px",
                  }}
                />
                {updatedCandidate.phone || candidate.phone}
              </p>
            </div>

            <div className="profile_complete">
              <p className="account_progress">Profile Progress</p>
              <h6>{Math.round(progress)}%</h6>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#e0e0df",
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    width: `${progress}%`,
                    height: "7px",
                    backgroundColor: "#4caf50",
                    transition: "width 0.5s ease-in-out",
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className="candidate_managerer_affiliate"
            ref={managerDropdownRef}
          >
            <div className="managers_affiliate">
              <p>
                Manager{" "}
                <span>
                  {selectedManagers.length > 0
                    ? selectedManagers.join(", ")
                    : candidate.managers && candidate.managers.length > 0
                    ? candidate.managers.join(", ")
                    : "None"}{" "}
                  <b
                    onClick={toggleManagerDropdown}
                    style={{ cursor: "pointer" }}
                  >
                    ▼
                  </b>
                </span>
              </p>
            </div>

            <div className="addmanager">
              <button
                className="add_manager_btn"
                onClick={toggleManagerDropdown}
              >
                Add Manager
              </button>
              {isManagerDropdownOpen && (
                <div className="dropdown-manager">
                  <input
                    type="text"
                    placeholder="Search manager..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="search-manager"
                  />
                  <ul>
                    {filteredManagers.map((manager, index) => (
                      <li
                        key={index}
                        onClick={() => handleManagerSelect(manager)}
                        style={{
                          backgroundColor: selectedManagers.includes(manager)
                            ? "rgba(224, 224, 224, 0.47)"
                            : "transparent",
                        }}
                      >
                        {manager}
                      </li>
                    ))}
                  </ul>
                  <button className="saveManager" onClick={handleSaveManagers}>
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <UpdateBasicInformation
          candidate={updatedCandidate}
          onUpdate={handleUpdate}
          isOpen={isModalOpen}
          onClose={toggleForm}
        />
      )}
    </div>
  );
}

export default CandidateInfo;

import React, { useState } from "react";
import Modal from "react-modal";
import "./SelectTargetIndustries.css";
import { toast, ToastContainer } from "react-toastify";
import { industriesList } from "./IndustriesList.js";

Modal.setAppElement("#root");

const SelectTargetIndustries = ({ isOpen, onClose, candidate, onUpdate }) => {
  const [currentTargets, setCurrentTargets] = useState(
    candidate.targetIndustries || []
  );
  const [inputValue, setInputValue] = useState(""); // For dynamic input
  const [suggestions, setSuggestions] = useState([]); // Filtered suggestions for industries

  const handleInputChange = (e) => {
    const input = e.target.value;
    setInputValue(input);

    if (input.length > 0) {
      const filteredSuggestions = industriesList.filter((industry) =>
        industry.toLowerCase().includes(input.toLowerCase())
      );

      if (!filteredSuggestions.includes(input)) {
        filteredSuggestions.push(`Create new: ${input}`);
      }

      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      const newIndustry = inputValue.startsWith("Create new:")
        ? inputValue.replace("Create new: ", "")
        : inputValue.trim();

      if (!currentTargets.includes(newIndustry)) {
        setCurrentTargets((prev) => [...prev, newIndustry]);
      }

      setInputValue("");
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    const newIndustry = suggestion.startsWith("Create new:")
      ? suggestion.replace("Create new: ", "")
      : suggestion;

    if (!currentTargets.includes(newIndustry)) {
      setCurrentTargets((prev) => [...prev, newIndustry]);
    }

    setInputValue("");
    setSuggestions([]);
  };

  const handleRemoveIndustry = (index) => {
    const updatedTargets = currentTargets.filter((_, i) => i !== index);
    setCurrentTargets(updatedTargets);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const apiUrl = process.env.REACT_APP_API_URL;

      const response = await fetch(
        `${apiUrl}/api/admin/candidates/${candidate._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...candidate,
            idno: candidate.recruiterFlowId || "",
            candidateType: candidate.type || "",
            targetIndustries: currentTargets,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update candidate");
      }

      const updatedCandidate = await response.json();

      toast.success("Candidate updated successfully!");
      if (onUpdate) {
        onUpdate({
          ...candidate,
          targetIndustries: currentTargets,
        });
      }

      setTimeout(() => {
        if (onClose) {
          onClose();
        }
      }, 2000);
    } catch (error) {
      toast.error("Error updating candidate: " + error.message);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="target-industries-popup popup-content">
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Type or search industries"
        />

        {suggestions.length > 0 && (
          <ul className="suggestions">
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                className="suggestion-item"
              >
                {suggestion}
              </li>
            ))}
          </ul>
        )}

        <div className="selected-industries">
          {currentTargets.map((industry, index) => (
            <span key={index} className="tag">
              {industry}
              <button onClick={() => handleRemoveIndustry(index)}>x</button>
            </span>
          ))}
        </div>

        <div className="form-btn">
          <button onClick={handleSubmit} className="btn-save-changes">
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default SelectTargetIndustries;

import React, { useState, useRef, useContext } from "react";
import "./UpdateBasicInformationPopup.css";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import { toast, ToastContainer } from "react-toastify";
import { statesWithCities } from "./LocationList";
import InputMask from "react-input-mask"; // Importing react-input-mask
function BasicInformationUpdateProfile({ candidate }) {
  const [formData, setFormData] = useState({
    fname: candidate.fname || "",
    lname: candidate.lname || "",
    email: candidate.email || "",
    phone: candidate.phone || "",
    idno: candidate.recruiterFlowId || "",
    state: candidate.state || "",
    city: candidate.city || "",
    candidateType: candidate.type,
    idealJobTitle: candidate.idealJobTitle || "",
    branch: candidate.branch,
    description: candidate.description || "",
    securityClearance: candidate.securityClearance || "",
    yearsOfExperience: candidate.yearsOfExperience || "",
    skillbridgeStartDate: candidate.skillbridgeStartDate || "",
    skillbridgeEndDate: candidate.skillbridgeEndDate || "",
    idealJobTitle: candidate.idealJobTitle || "",
    duringSkillbridgeCity: candidate.duringSkillbridgeCity || "",
    duringSkillbridgeState: candidate.duringSkillbridgeState || "",
    afterskillbridgeCity: candidate.afterskillbridgeCity || "",
    afterskillbridgeState: candidate.afterskillbridgeState || "",
    relocationprefrence: candidate.relocationprefrence || "",
    education: candidate.education || "",
    idno: candidate.recruiterFlowId || "", // This ID is from RecruiterFlow
    branch: candidate.branch || "", // Optional chaining
    rank: candidate.rank || "",
    mos: candidate.mos || "",
    yearsServed: candidate.yearsServed || "",
    targetIndustries: candidate.targetIndustries || "",
    certifications: candidate.certifications || "",
  });

  const [errors, setErrors] = useState({});
  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (value) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.fname) newErrors.fname = "This field can not be empty";
    if (!formData.lname) newErrors.lname = "This field can not be empty";

    if (!formData.phone) newErrors.phone = "This field can not be empty";
    if (!formData.idno) newErrors.idno = "This field can not be empty";
    if (!formData.city) newErrors.city = "This field can not be empty";
    if (!formData.state) newErrors.state = "This field can not be empty";
    if (!formData.candidateType)
      newErrors.candidateType = "This field is empty";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    try {
      const response = await fetch(
        `http://localhost:5000/api/candidates/${candidate._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update candidate");
      }

      const updatedCandidate = await response.json();
      toast.success("Candidate updated successfully!");

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast.error("Error updating candidate: " + error.message);
    }
  };

  const inputref = useRef(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDV7VADzUdaHFisJU5k_6I454xH9qnuQz0",
    libraries: ["places"],
  });

  const handleOnPlacesChanged = () => {
    let places = inputref.current.getPlaces();

    if (places && places.length > 0) {
      const addressComponents = places[0].address_components;

      if (addressComponents) {
        let cityName = "";
        let stateName = "";

        addressComponents.forEach((component) => {
          if (component.types.includes("locality")) {
            cityName = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            stateName = component.long_name;
          }
        });

        // Update the formData state
        setFormData((prevData) => ({
          ...prevData,
          city: cityName,
          state: stateName,
        }));
      } else {
        console.error("No address components found.");
      }
    } else {
      console.error("No places found.");
    }
  };
  return (
    <div className="popup-content complete-profile-form">
      <ToastContainer />
      <div className="popup-header">
        <h2>Edit Your Basic Information</h2>
        <p>
          Ensure your contact details are up to date so companies can easily
          reach you.
        </p>
      </div>
      <hr />

      <form className="popup-form " onSubmit={handleSubmit}>
        <div className="two-fields">
          <div className="form-field">
            <label>
              First Name <span>*</span>
            </label>
            <input
              type="text"
              placeholder="Type Here"
              name="fname"
              value={formData.fname}
              className={!formData.fname ? "error-border" : ""}
              onChange={handleChange}
            />

            {errors.fname && (
              <span className="error-message-field">{errors.fname}</span>
            )}
          </div>

          <div className="form-field">
            <label>
              Last Name <span>*</span>
            </label>
            <input
              type="text"
              placeholder="Type Here"
              name="lname"
              value={formData.lname}
              className={!formData.lname ? "error-border" : ""}
              onChange={handleChange}
            />

            {errors.lname && (
              <span className="error-message-field">{errors.lname}</span>
            )}
          </div>
        </div>

        <div className="two-fields">
          <div className="form-field">
            <label>
              Email <span>*</span>
            </label>
            <input
              type="email"
              placeholder="abc@7eagle.com"
              name="email"
              value={formData.email}
              readOnly
              style={{ backgroundColor: "#f6f6f6" }}
            />
          </div>
        </div>

        <div className="two-fields">
          <div className="form-field">
            <label>
              Phone <span>*</span>
            </label>
            <InputMask
              mask="(999) 999-9999" // Masking for USA phone number
              placeholder="(123) 456-7890"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className={!formData.phone ? "error-border" : "form-input"}
            />

            {errors.phone && (
              <span className="error-message-field">{errors.phone}</span>
            )}
          </div>

          <div className="form-field">
            <label>
              ID Number <span>*</span>
            </label>
            <input
              type="text"
              placeholder="Type Here"
              name="idno"
              value={formData.idno}
              readOnly
              style={{ backgroundColor: "#f6f6f6" }}
            />
          </div>
        </div>

        <div className="form-field" style={{ marginBottom: "15px" }}>
          {isLoaded && (
            <div>
              <label>
                Location <span>*</span>
              </label>
              <StandaloneSearchBox
                onLoad={(ref) => (inputref.current = ref)}
                onPlacesChanged={handleOnPlacesChanged}
                style={{
                  zIndex: "10000",
                  position: "absolute",
                  top: "0px",
                  width: "100%",
                }} // Higher z-index for the search box
              >
                <input
                  type="text"
                  placeholder="Search location"
                  style={{
                    width: "100%",
                    padding: "14px",
                    borderRadius: "10px",
                    border: "1px solid #ddddde",
                  }}
                  className={!formData.city ? "error-border" : ""}
                />
              </StandaloneSearchBox>

              {errors.city && (
                <span className="error-message-field">{errors.city}</span>
              )}
            </div>
          )}
        </div>
        <div className="field-wrap form-field">
          <label>
            Describe Yourself <span>*</span>
          </label>
          <div className="checkbox-main">
            <div
              className="checkboxs"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input
                type="radio"
                value="Transitioning Service Member"
                checked={
                  formData.candidateType === "Transitioning Service Member"
                }
                name="candidateType"
                onChange={handleChange}
                style={{ width: "20px", marginRight: "10px" }}
              />
              <span>Transitioning Service Member</span>
            </div>

            <div
              className="checkboxs"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input
                type="radio"
                value="Veteran"
                name="candidateType"
                checked={formData.candidateType === "Veteran"}
                onChange={handleChange}
                style={{ width: "20px", marginRight: "10px" }}
              />
              <span>Veteran</span>
            </div>
          </div>
        </div>

        <hr style={{ marginTop: "40px" }} />

        <div className="form-btn">
          <button type="submit" className="primary-btn">
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
}

export default BasicInformationUpdateProfile;

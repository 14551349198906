import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./SubmitToCompany.css";

const SubmitToCompanyForm = ({ candidate }) => {
  const [companyName, setCompanyName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [recruiterNotes, setRecruiterNotes] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setIsSubmitting(true);

    const formData = {
      submitToCompanies: [
        {
          companyName,
          jobTitle,
          recruiterNotes,
        },
      ],
    };

    try {
      const apiUrl = process.env.REACT_APP_API_URL;

      // Sending PUT request to update candidate data
      const response = await fetch(
        `${apiUrl}/api/admin/candidates/submit/${candidate._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        // Success toast
        toast.success("Submitted to company successfully!");
        // Reset form fields after submission
        setCompanyName("");
        setJobTitle("");
        setRecruiterNotes("");
      } else {
        // Error toast
        toast.error("Error updating candidate submission.");
      }
    } catch (error) {
      // Handle network error and show error toast
      console.error("Error:", error);
      toast.error("Network error, please try again.");
    } finally {
      setIsSubmitting(false); // Reset submitting state
    }
  };

  return (
    <div className="submit_company_section">
      <ToastContainer />
      <div className="submit_header">
        <h3> Submit Candidate to a Company</h3>
      </div>

      <div className="submit_body">
        <form>
          <div>
            <label htmlFor="companyName">
              Which company would you like to submit this candidate to?
            </label>
            <input
              type="text"
              id="companyName"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              required
            />
          </div>

          <div>
            <label htmlFor="jobTitle">
              Which job are you presenting this candidate for? (Optional)
            </label>
            <input
              type="text"
              id="jobTitle"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
            />
          </div>

          <div>
            <label htmlFor="recruiterNotes">
              Add any recruiter notes or details for this submission:
            </label>
            <textarea
              id="recruiterNotes"
              value={recruiterNotes}
              onChange={(e) => setRecruiterNotes(e.target.value)}
            ></textarea>
          </div>
        </form>
      </div>

      <div className="submit_footer">
        <button
          type="button"
          onClick={() => {
            setCompanyName("");
            setJobTitle("");
            setRecruiterNotes("");
          }}
        >
          Cancel
        </button>
        <button type="submit" onClick={handleSubmit} disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Present To Company"}
        </button>
      </div>
    </div>
  );
};

export default SubmitToCompanyForm;

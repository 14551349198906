import React, { useState } from "react";
import axios from "axios";
import "./CompanyVerification.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Ensure you import the CSS
import verifiedIcon from "../../../../../assets/imgs/verified.png";

function CompanyVerification({ company, initialStatus }) {
  const [isVerified, setIsVerified] = useState(company?.isVerified || false);

  const toggleVerification = async () => {
    try {
      const updatedStatus = !isVerified; // Toggle the current status
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.put(
        `${apiUrl}/api/admin/clients/verify/${company._id}`,
        {
          isVerified: updatedStatus,
        }
      );
      if (response.status === 200) {
        setIsVerified(updatedStatus); // Update local state
        toast.success("Updated Successful");
      }
    } catch (error) {
      console.error("Error updating verification status:", error);
      toast.error("Error", error);
      // You can add error handling here if needed
    }
  };

  return (
    <div className="verification_status_section">
      <h4>Verification Status</h4>

      <div className="verificaiton_button_list">
        <div className="verification_list">
          <p>
            <img
              src={verifiedIcon}
              alt="verifiedIcon"
              className="verifiedIcon"
            />
            {isVerified ? "Verified" : "Unverified"}
          </p>
        </div>
        <div className="verified_btn">
          <button onClick={toggleVerification}>
            {isVerified ? "Remove Verification" : "Verify Company"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CompanyVerification;

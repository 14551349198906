// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ql-container{
    min-height: 200px;
    background-color: white;
}

.ql-toolbar.ql-snow{
    background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/admin/dashboard/Candidates/TextEditor/TextEditor.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".ql-container{\n    min-height: 200px;\n    background-color: white;\n}\n\n.ql-toolbar.ql-snow{\n    background-color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext"; // Import AuthProvider
import PrivateRoute from "./components/PrivateRoute"; // Import PrivateRoute
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AdminCandidateDashboard from "./pages/admin/CandidateProfileTable";
import CandidateCompleteProfile from "./pages/admin/CandidateCompleteProfile";
import CandidateProfilePage from "./pages/admin/CandidateProfilePage";
import "react-toastify/dist/ReactToastify.css";
import Courses from "./pages/Courses/Course-Page";
import CourseDetail from "./pages/Courses/CourseDetail";
import CandidatePublicProfile from "./pages/admin/CandidatePublicProfile";
import ClientRecords from "./pages/admin/Company/CompanyProfilePage";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/admin/candidates" element={<PrivateRoute><AdminCandidateDashboard /></PrivateRoute>} />
          <Route path="/admin/companies" element={<PrivateRoute><ClientRecords /></PrivateRoute>} />
          <Route path="/admin/profile-page" element={<PrivateRoute><CandidateProfilePage /></PrivateRoute>} />
          <Route path="/admin/courses" element={<PrivateRoute><Courses /></PrivateRoute>} />
          <Route path="/admin/course/:id" element={<PrivateRoute><CourseDetail /></PrivateRoute>} />
          <Route path="/admin/profile/:userId" element={<PrivateRoute><CandidatePublicProfile /></PrivateRoute>} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;

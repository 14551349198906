// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.target_industries
{
    background-color: white;
    padding: 20px 20px;
    border-radius: 10px;
    box-shadow: 0px 1px 7px #7a7a7a2b;
    padding-bottom: 5px;
    margin-top: 20px;

    position: relative;
}

.target_industries h4{
    font-size: 16px;
    font-weight: 600;
    
}

.empty-state {
    margin: 0 auto;
    text-align: center;
    
    padding-bottom: 30px;
}
.empty-state img{
    width: 160px;

    
}
.empty-state p{
    color: #7D8287;
    font-size: 17px;
    padding-bottom: 10px;
}
.empty-state button{

    margin-bottom: 10px;
    border: 1px solid #ffc500;
    color: #5f6063;
    background-color: #ffc500;
    padding: 13px 30px;
    font-weight: 400;
    border-radius: 10px;
    font-size: 14px;
}

.empty-state .plusIcon img{
    width: 30px;
    position: absolute;
    right: 2%;
    top: 6%;
    cursor: pointer;
}

.branch-value:first-child{
    padding-top: 20px;
}
.branch-value{
    padding-bottom: 15px;
}

.branch-value b{
    font-weight: 600;
    font-size: 14px;
}

.branch-value p{
    padding-bottom: 0px;
    margin-bottom: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/admin/dashboard/Candidates/Military Background/MilitaryBackground.css"],"names":[],"mappings":"AAAA;;IAEI,uBAAuB;IACvB,kBAAkB;IAClB,mBAAmB;IACnB,iCAAiC;IACjC,mBAAmB;IACnB,gBAAgB;;IAEhB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;;AAEpB;;AAEA;IACI,cAAc;IACd,kBAAkB;;IAElB,oBAAoB;AACxB;AACA;IACI,YAAY;;;AAGhB;AACA;IACI,cAAc;IACd,eAAe;IACf,oBAAoB;AACxB;AACA;;IAEI,mBAAmB;IACnB,yBAAyB;IACzB,cAAc;IACd,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;AACA;IACI,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".target_industries\n{\n    background-color: white;\n    padding: 20px 20px;\n    border-radius: 10px;\n    box-shadow: 0px 1px 7px #7a7a7a2b;\n    padding-bottom: 5px;\n    margin-top: 20px;\n\n    position: relative;\n}\n\n.target_industries h4{\n    font-size: 16px;\n    font-weight: 600;\n    \n}\n\n.empty-state {\n    margin: 0 auto;\n    text-align: center;\n    \n    padding-bottom: 30px;\n}\n.empty-state img{\n    width: 160px;\n\n    \n}\n.empty-state p{\n    color: #7D8287;\n    font-size: 17px;\n    padding-bottom: 10px;\n}\n.empty-state button{\n\n    margin-bottom: 10px;\n    border: 1px solid #ffc500;\n    color: #5f6063;\n    background-color: #ffc500;\n    padding: 13px 30px;\n    font-weight: 400;\n    border-radius: 10px;\n    font-size: 14px;\n}\n\n.empty-state .plusIcon img{\n    width: 30px;\n    position: absolute;\n    right: 2%;\n    top: 6%;\n    cursor: pointer;\n}\n\n.branch-value:first-child{\n    padding-top: 20px;\n}\n.branch-value{\n    padding-bottom: 15px;\n}\n\n.branch-value b{\n    font-weight: 600;\n    font-size: 14px;\n}\n\n.branch-value p{\n    padding-bottom: 0px;\n    margin-bottom: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

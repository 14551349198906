import React, { useState, useContext } from "react";
import "../Military Background/Add Military/AddMilitary.css";

import { toast, ToastContainer } from "react-toastify";
import { ranks } from "./rankList";
import { mosList } from "./mosList";

function CompleteProfileMilitaryBackground({ candidate, onClose, onUpdate }) {
  const [formData, setFormData] = useState({
    jobSearchStatus: candidate.jobSearchStatus || "3",
    jobSearchStatusName:
      candidate.jobSearchStatusName ||
      "Casually Exploring – Browsing opportunities.",
    branch: candidate.branch || "", // Optional chaining
    rank: candidate.rank || "",
    mos: candidate.mos || "",
    yearsServed: candidate.yearsServed || "",
    fname: candidate.fname || "",
    lname: candidate.lname || "",
    candidateType: candidate.type || "",
    state: candidate.state || "",
    city: candidate.city || "",
    description: candidate.description || "",
    securityClearance: candidate.securityClearance || "",
    yearsOfExperience: candidate.yearsOfExperience || "",
    skillbridgeStartDate: candidate.skillbridgeStartDate || "",
    skillbridgeEndDate: candidate.skillbridgeEndDate || "",
    idealJobTitle: candidate.idealJobTitle || "",
    duringSkillbridgeCity: candidate.duringSkillbridgeCity || "",
    duringSkillbridgeState: candidate.duringSkillbridgeState || "",
    afterskillbridgeCity: candidate.afterskillbridgeCity || "",
    afterskillbridgeState: candidate.afterskillbridgeState || "",
    relocationprefrence: candidate.relocationprefrence || "",
    education: candidate.education || "",
    idno: candidate.recruiterFlowId || "", // Yeh ID RecruiterFlow ki hai
    email: candidate.email || "",
    phone: candidate.phone || "",
    certifications: candidate.certifications || "",
    Pursuingcertifications: candidate.Pursuingcertifications || "",
    targetIndustries: candidate.targetIndustries || "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const apiUrl = process.env.REACT_APP_API_URL;

    try {
      const response = await fetch(
        `${apiUrl}/api/admin/candidates/${candidate._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update candidate");
      }

      const updatedCandidate = await response.json();
      toast.success("Candidate updated successfully!"); // Success message
      if (onUpdate) {
        onUpdate(formData); // Send updated candidate to the parent component
      }

      setTimeout(() => {
        if (onClose) {
          onClose(); // This will close the form by changing the state in the parent
        }
      }, 2000); // Close the form after the update
    } catch (error) {
      toast.error("Error updating candidate: " + error.message);
    }
  };

  // Sample options for branches and ranks
  const branches = ["Army", "Navy", "Air Force"];

  // Handle dropdown change
  const handleBranchChange = (e) => {
    setFormData({ ...formData, branch: e.target.value });
  };

  const handleRankChange = (e) => {
    setFormData({ ...formData, rank: e.target.value });
  };

  const handleMosChange = (e) => {
    setFormData({ ...formData, mos: e.target.value });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  return (
    <div className="popup-content">
      <div className="popup-form add-military">
        <div className="form-field">
          <label>
            Branch <span>*</span>
          </label>
          <select
            value={formData.branch}
            onChange={handleBranchChange}
            name="branch"
          >
            <option value="">Select Branch</option>
            {branches.map((loc, index) => (
              <option key={index} value={loc}>
                {loc}
              </option>
            ))}
          </select>
        </div>

        <div className="form-field">
          <label>
            MOS <span>*</span>
          </label>
          <select value={formData.mos} onChange={handleMosChange} name="mos">
            <option value="">Select MOS</option>
            {mosList.enlisted.map((st, index) => (
              <option key={index} value={st}>
                {st}
              </option>
            ))}
          </select>
        </div>

        <div className="form-field">
          <label>
            Rank <span>*</span>
          </label>
          <select value={formData.rank} onChange={handleRankChange} name="rank">
            <option value="">Select Rank</option>
            {ranks.enlisted.map((st, index) => (
              <option key={index} value={st}>
                {st}
              </option>
            ))}
          </select>
        </div>

        <div className="form-field">
          <label>
            Years Served <span>*</span>
          </label>
          <input
            type="text"
            placeholder="Type Here"
            name="yearsServed"
            value={formData.yearsServed}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="form-btn">
        <button onClick={handleSubmit} className="btn-save-changes">
          Save
        </button>
      </div>
    </div>
  );
}

export default CompleteProfileMilitaryBackground;

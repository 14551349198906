import React, { useState, useEffect, useRef } from "react";
import "./JobSearchStatusFilter.css";
import iconUser from "../../../../../assets/imgs/icon-job-fill.png";
import editIcon from "../../../../../assets/imgs/Edit.png";
import delIcon from "../../../../../assets/imgs/trash.png";

const JobSearchStatusFilter = ({
  isOpen,
  onClose,
  candidateData,
  onSelectStatus,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [filterType, setFilterType] = useState("is one of");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const modalRef = useRef(null);

  // Filtering logic, now based only on jobSearchStatusName
  const filteredStatuses =
    searchTerm.length >= 1
      ? candidateData.filter((candidate) => {
          const statusName = candidate.jobSearchStatusName.toLowerCase() || "";
          const searchWords = searchTerm.trim().toLowerCase().split(/\s+/);

          switch (filterType) {
            case "is one of":
              // Search only in jobSearchStatusName
              return searchWords.every((word) => statusName.includes(word));
            case "is not one of":
              // Search for candidates where jobSearchStatusName does not match
              return searchWords.some((word) => !statusName.includes(word));
            case "is set":
              // Check if jobSearchStatusName is set (non-empty)
              return statusName.length > 0;
            case "is not set":
              // Check if jobSearchStatusName is not set (empty)
              return statusName.length === 0;
            default:
              return false;
          }
        })
      : [];

  useEffect(() => {
    const handleOutsideClick = (event) => {};

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  const handleSelectStatus = (statusName) => {
    setSelectedStatus({ statusName });
    setSearchTerm(statusName); // Only set the jobSearchStatusName as the search term
  };

  const handleDone = () => {
    if (selectedStatus) {
      onSelectStatus(selectedStatus);
      setIsConfirmed(true);
    }
  };

  const handleEdit = () => {
    setIsConfirmed(false);
  };

  const handleDelete = () => {
    setSelectedStatus(null);
    setSearchTerm("");
    setIsConfirmed(false);
  };

  if (!isOpen) return null;

  return (
    <div className="job-search-status-modal-main">
      <div className="job-search-status-modal-content" ref={modalRef}>
        {isConfirmed ? (
          <div className="confirmation-view">
            <div className="selected_header">
              <div>
                <h6>
                  <img src={iconUser} alt="icon-user" className="icon-user" />
                  Job Search Status
                </h6>
              </div>
              <div className="manage-icons">
                <img
                  src={editIcon}
                  alt="edit-icon"
                  className="edit-Icon"
                  onClick={handleEdit}
                />
                <img
                  src={delIcon}
                  alt="del-icon"
                  className="deleteIcon"
                  onClick={handleDelete}
                />
              </div>
            </div>
            <p className="selected-status-name">{selectedStatus.statusName}</p>
          </div>
        ) : (
          <>
            <div className="filter-options">
              <h6>
                <img src={iconUser} alt="icon-user" className="icon-user" />
                Job Search Status
              </h6>
              <div className="status-list-radio-buttons">
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is one of"
                    checked={filterType === "is one of"}
                    onChange={(e) => setFilterType(e.target.value)}
                  />
                  Is one of
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is not one of"
                    checked={filterType === "is not one of"}
                    onChange={(e) => setFilterType(e.target.value)}
                  />
                  Is not one of
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is set"
                    checked={filterType === "is set"}
                    onChange={(e) => setFilterType(e.target.value)}
                  />
                  Is set
                </label>
                <label>
                  <input
                    type="radio"
                    name="filter"
                    value="is not set"
                    checked={filterType === "is not set"}
                    onChange={(e) => setFilterType(e.target.value)}
                  />
                  Is not set
                </label>
              </div>
            </div>
            <input
              type="text"
              placeholder="Search Job Search Status"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="status-search-bar"
            />
            <div className="status-list">
              {searchTerm.length >= 2 && filteredStatuses.length === 0 ? (
                <div className="no-results">No Match Result</div>
              ) : (
                filteredStatuses.map((candidate, index) => (
                  <div
                    key={index}
                    className="status-item"
                    onClick={() =>
                      handleSelectStatus(candidate.jobSearchStatusName)
                    }
                  >
                    {candidate.jobSearchStatusName}
                  </div>
                ))
              )}
            </div>

            <div className="modal-footer">
              <button className="done-button" onClick={handleDone}>
                Done
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default JobSearchStatusFilter;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.note_list_section .note_box{
    background-color: white;
    padding:20px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.note_list_section{
    padding: 20px 0px;
}

.user_info_date{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
}

.user_info_date h6{
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 0;
}


.note_list_section h1{
    font-size: 24px;
}

.note_list_section .user_profile_info{
    display: flex;
    align-items: center;

}

.note_list_section .user_profile_info img{
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.note_list_section p{
    font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/admin/dashboard/Companies/Notes List/NotesList.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,oBAAoB;AACxB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,0BAA0B;IAC1B,gBAAgB;AACpB;;;AAGA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;;AAEvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".note_list_section .note_box{\n    background-color: white;\n    padding:20px;\n    border-radius: 10px;\n    margin-bottom: 10px;\n}\n\n.note_list_section{\n    padding: 20px 0px;\n}\n\n.user_info_date{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding-bottom: 15px;\n}\n\n.user_info_date h6{\n    font-size: 14px;\n    font-weight: 600;\n    text-transform: capitalize;\n    margin-bottom: 0;\n}\n\n\n.note_list_section h1{\n    font-size: 24px;\n}\n\n.note_list_section .user_profile_info{\n    display: flex;\n    align-items: center;\n\n}\n\n.note_list_section .user_profile_info img{\n    width: 20px;\n    height: 20px;\n    margin-right: 10px;\n}\n\n.note_list_section p{\n    font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

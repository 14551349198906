// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content {
    background: #fff;
    padding: 20px;
    width: 400px;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .modal-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  
  .upload-box {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
  }

  .main-progress-bar{
    width: 95%;
    
  }
  
  .image-preview img {
    max-width: 100px;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  
  .progress-bar {
    background: #f3f3f3;
    border-radius: 5px;
    overflow: hidden;
    margin: 10px 0;
  }
  
  .progress {
    background: #4caf50;
    height: 10px;
    transition: width 0.3s;
  }
  
  .error-message {
    color: red;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/admin/dashboard/ProfileImagePopup/ProfileImageUpload.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,wCAAwC;EAC1C;;EAEA;IACE,8BAA8B;EAChC;;EAEA;IACE,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,UAAU;;EAEZ;;EAEA;IACE,gBAAgB;IAChB,YAAY;IACZ,cAAc;IACd,cAAc;EAChB;;EAEA;IACE,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,mBAAmB;IACnB,YAAY;IACZ,sBAAsB;EACxB;;EAEA;IACE,UAAU;EACZ","sourcesContent":[".modal-content {\n    background: #fff;\n    padding: 20px;\n    width: 400px;\n    margin: auto;\n    border-radius: 10px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  }\n  \n  .modal-overlay {\n    background: rgba(0, 0, 0, 0.5);\n  }\n  \n  .upload-box {\n    border: 2px dashed #ccc;\n    padding: 20px;\n    text-align: center;\n    cursor: pointer;\n  }\n\n  .main-progress-bar{\n    width: 95%;\n    \n  }\n  \n  .image-preview img {\n    max-width: 100px;\n    height: auto;\n    display: block;\n    margin: 0 auto;\n  }\n  \n  .progress-bar {\n    background: #f3f3f3;\n    border-radius: 5px;\n    overflow: hidden;\n    margin: 10px 0;\n  }\n  \n  .progress {\n    background: #4caf50;\n    height: 10px;\n    transition: width 0.3s;\n  }\n  \n  .error-message {\n    color: red;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

export const IdealJobList = [
  "Cybersecurity Analyst",
  "Information Security Analyst",
  "Cybersecurity Engineer",
  "Security Operations Center (SOC) Analyst",
  "Security Consultant",
  "Penetration Tester (Ethical Hacker)",
  "Vulnerability Analyst",
  "Incident Response Analyst",
  "Threat Intelligence Analyst",
  "Cyber Defense Analyst",
  "Application Security Engineer",
  "Security Architect",
  "Security Auditor",
  "Cryptographer",
  "Digital Forensics Analyst",
  "Governance, Risk & Compliance (GRC) Specialist",
  "Cybersecurity Compliance Officer",
  "Cyber Risk Manager",
  "Cybersecurity Instructor",
  "Chief Information Security Officer (CISO)",
  "Malware Analyst",
  "Cybersecurity Specialist",
  "Information Assurance Specialist",
  "Security Compliance Analyst",
  "Security Incident Handler",
  "Information Security Officer",
  "Cybersecurity Project Manager",
  "Cybersecurity Threat Hunter",
  "Cybersecurity Risk Analyst",
  "Cybersecurity Policy Analyst",
  "Cybersecurity Researcher",
  "Cybersecurity Trainer",
  "Cybersecurity Solutions Architect",
  "Cyber Intelligence Analyst",
  "IT Support Specialist",
  "Help Desk Technician",
  "IT Help Desk Analyst",
  "Desktop Support Technician",
  "Technical Support Engineer",
  "Systems Administrator",
  "Network Administrator",
  "Cloud Administrator",
  "IT Manager",
  "IT Director",
  "Chief Technology Officer (CTO)",
  "Database Administrator (DBA)",
  "IT Consultant",
  "IT Infrastructure Engineer",
  "IT Security Analyst",
  "IT Auditor",
  "DevOps Engineer",
  "DevSecOps Engineer",
  "Cloud Engineer",
  "Cloud Solutions Architect",
  "Network Engineer",
  "Systems Engineer",
  "IT Project Manager",
  "Infrastructure Project Manager",
  "Enterprise Architect",
  "Application Support Analyst",
  "Business Systems Analyst",
  "Data Center Technician",
  "Data Network Technician",
  "Server Administrator",
  "IT Trainer",
  "Cloud Security Engineer",
  "Cloud Developer",
  "Cloud Migration Specialist",
  "Network Security Engineer",
  "Network Analyst",
  "Network Security Administrator",
  "Senior Network Engineer",
  "Wireless Network Engineer",
  "Systems Analyst",
  "Systems Integration Engineer",
  "Linux Systems Administrator",
  "Windows Systems Administrator",
  "Software Developer",
  "Software Engineer",
  "Full Stack Developer",
  "Mobile App Developer",
  "Front-End Developer",
  "Back-End Developer",
  "Web Developer",
  "UI Developer",
  "Game Developer",
  "Application Developer",
  "Software Development Engineer in Test (SDET)",
  "UI/UX Designer",
  "User Experience Researcher",
  "Software Architect",
  "Systems Software Engineer",
  "Integration Developer",
  "Business Application Developer",
  "Data Analyst",
  "Data Scientist",
  "Big Data Engineer",
  "Data Architect",
  "Machine Learning Engineer",
  "AI Engineer",
  "Data Engineer",
  "Business Intelligence Analyst",
  "Data Visualization Specialist",
  "Statistical Analyst",
  "Predictive Analytics Specialist",
  "Data Quality Analyst",
  "Data Governance Analyst",
  "ETL Developer",
  "Data Warehouse Developer",
  "IT Process Improvement Specialist",
  "Change Management Analyst",
  "IT Service Continuity Manager",
  "IT Performance Analyst",
  "IT Product Manager",
  "IT Operations Analyst",
  "Technology Risk Consultant",
  "Help Desk Manager",
  "Blockchain Developer",
  "Blockchain Security Engineer",
  "Augmented Reality (AR) Developer",
  "Virtual Reality (VR) Developer",
  "AI Product Manager",
  "Data Privacy Officer",
  "Digital Transformation Consultant",
  "Robotic Process Automation (RPA) Developer",
  "Internet of Things (IoT) Engineer",
  "Edge Computing Engineer",
  "Quantum Systems Engineer",
  "IT Generalist",
  "Systems Integrator",
  "Business Systems Consultant",
  "Technology Analyst",
  "Systems Support Analyst",
  "IT Training Specialist",
  "IT Compliance Manager",
  "Registered Nurse (RN)",
  "Licensed Practical Nurse (LPN)",
  "Nurse Practitioner (NP)",
];

// src/utils/logger.js

const log = (level, message) => {
  const timestamp = new Date().toISOString();
  console.log(`${timestamp} [${level}]: ${message}`);
};

const logger = {
  info: (message) => log('INFO', message),
  error: (message) => log('ERROR', message),
};

export default logger;
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import dashboardIcon from "../../../../assets/imgs/dash-icon.png";
import userIcon from "../../../../assets/imgs/user-icon-new.png";
import jobIcon from "../../../../assets/imgs/job-icon.png";
import CompaniesIcon from "../../../../assets/imgs/comapny-icon.png";

import collapsedIcon from "../../../../assets/imgs/collapse.png";
import upgradeIcon from "../../../../assets/imgs/upgrade.png";

import "../Sidebar/Sidebar.css";

const Sidebar = ({ onToggleSidebar }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation(); // Hook to get the current location

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
    onToggleSidebar(!isCollapsed); // Pass the updated state to Dashboard.js
  };

  return (
    <aside className={isCollapsed ? "collapsed" : ""}>
      <ul style={{ marginTop: "10px" }}>
        <li
          className={location.pathname === "/admin/dashboard" ? "active" : ""}
        >
          <Link to="/admin/dashboard">
            <img
              src={dashboardIcon}
              className="sidebar-icons"
              alt="dashbordicon"
            />
            {!isCollapsed && "Dashboard"}
          </Link>
        </li>
        <li
          className={location.pathname === "/admin/candidates" ? "active" : ""}
        >
          <Link to="/admin/candidates">
            <img src={userIcon} className="sidebar-icons" alt="usericon" />
            {!isCollapsed && "Candidates"}
          </Link>
        </li>
        <li className={location.pathname === "/admin/jobs" ? "active" : ""}>
          <Link to="/admin/jobs">
            <img src={jobIcon} className="sidebar-icons" alt="Jobicon" />
            {!isCollapsed && "Jobs"}
          </Link>
        </li>
        <li
          className={location.pathname === "/admin/companies" ? "active" : ""}
        >
          <Link to="/admin/companies">
            <img
              src={CompaniesIcon}
              className="sidebar-icons"
              alt="companiesicon"
            />
            {!isCollapsed && "Companies"}
          </Link>
        </li>
      </ul>
      <div className="toggle-btn">
        <div className="upgrade-btn">
          <Link to="/upgrade">
            <img src={upgradeIcon} className="sidebar-icons" alt="senticon" />
            {!isCollapsed && "Upgrade Account"}
          </Link>
        </div>
        <div onClick={toggleSidebar} className="collapse-btn">
          {isCollapsed ? (
            <img src={collapsedIcon} alt="expand icon" />
          ) : (
            <>
              <img
                src={collapsedIcon}
                className="sidebar-icons"
                alt="collapse icon"
              />{" "}
              Collapse Menu{" "}
            </>
          )}
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;

import React, { useContext, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import { Link } from "react-router-dom";
import logo from "../../../../assets/imgs/logo-7eagle.png";
import bellIcon from "../../../../assets/imgs/NotificationIcon.png"; // Notification Icon
import "../Header/Header.css"; // For Header component

const Header = ({ admin }) => {
  // const { logout, isAuthenticated } = useContext(AuthContext); // Get isAuthenticated from context
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const BASE_URL = `${process.env.REACT_APP_API_URL}`;
  // Use optional chaining and fallback for profileImage
  const profileImage = admin?.headshotLink
    ? `${BASE_URL}/${admin.headshotLink}`
    : null;

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <header className="header">
      <Link to="/admin/dashboard">
        <img src={logo} alt="logo" className="logo-img" />
      </Link>

      <nav>
        <ul>
          <Link to="/admin/notifications">
            <img src={bellIcon} alt="bellIcon" className="bellIcon-img" />
          </Link>
          <li className="profile-menu" onClick={toggleDropdown}>
            <div>
              {admin ? (
                admin.headshotLink ? (
                  <div className="exist-profile-image-header">
                    <img
                      src={profileImage}
                      alt="Profile"
                      style={{ marginRight: "10px" }}
                    />
                  </div>
                ) : (
                  <div className="profile-image">
                    <h2>{admin.name.charAt(0)}</h2>
                  </div>
                )
              ) : (
                <div className="profile-image">
                  <h2>?</h2>{" "}
                  {/* Placeholder for when candidate is not available */}
                </div>
              )}
            </div>

            <span className="arrow">▼</span>
            {isDropdownOpen && (
              <div className="dropdown-menu-profile">
                <button>Logout</button>
              </div>
            )}
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;

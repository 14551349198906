import React, { useState } from "react";
import Modal from "react-modal";
import CompleteProfileImageUpload from "./CompleteProfileImageUplaod"; // Image upload component
import "./ProfileImageUpload.css";

// Modal setup
Modal.setAppElement("#root");

const ProfileImageUpload = ({ isOpen, onClose, candidate, onImageUpload }) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "45%",
      marginRight: "-50%",
      position: "absolute",
      transform: "translate(-50%, -50%)",
      borderRadius: "10px",
      padding: "0px",
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Upload Photo"
      onRequestClose={onClose}
      style={customStyles}
    >
      <div className="popup-content">
        <button onClick={onClose} className="close-btn">
          X
        </button>
        <CompleteProfileImageUpload
          onClose={(image) => {
            onClose(); // Close modal
          }}
          candidate={candidate}
        />
      </div>
    </Modal>
  );
};

export default ProfileImageUpload;

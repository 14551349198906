import React, { useEffect, useState } from "react";

import Header from "../../components/admin/dashboard/Header/Header";
import Sidebar from "../../components/admin/dashboard/Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/imgs/logo-7eagle.png";
import axios from "axios";
import "./AdminDashboard.css";
import BasicInformationUpdateProfile from "../../components/admin/dashboard/Candidates/Profile Information/BasicInformationUpdateProfile";
import CompleteProfileAbout from "../../components/admin/dashboard/CandidateAbout/EditAbout/CompleteProfileAbout";
import CompleteProfileMilitaryBackground from "../../components/admin/dashboard/Candidates/Military Background/CompleteProfileMilitaryBackground";
import CompleteProfileImageUplaod from "../../components/admin/dashboard/ProfileImagePopup/CompleteProfileImageUplaod";
import { ToastContainer } from "react-toastify";

const CandidateCompleteProfile = ({ onClose }) => {
  const navigate = useNavigate();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [candidate, setCandidate] = useState(null);
  const [loading, setLoading] = useState(true);

  // Track incomplete steps and current step
  const [incompleteSteps, setIncompleteSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    const fetchCandidate = async () => {
      try {
        const config = {};

        const userId = "67099545f6fe8bcd6e2543d3"; // Example userId
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/candidates/user/${userId}`,
          config
        );

        setCandidate(response.data.data);
      } catch (error) {
        console.error(
          "Candidate data fetch karne mein ghalti:",
          error.response ? error.response.data : error.message
        );
      } finally {
        setLoading(false);
      }
    };

    fetchCandidate();
  }, [navigate]);

  useEffect(() => {
    if (!candidate) return;

    const steps = [];
    // Check if candidate's basic info fields are empty
    const hasBasicInfo =
      candidate.fname &&
      candidate.lname &&
      candidate.phone &&
      candidate.city &&
      candidate.state;

    if (!hasBasicInfo) {
      steps.push(1); // Step 1: Basic info
    }

    // Check if candidate's detailed profile fields are filled
    const hasCompleteProfileInfo =
      candidate.description &&
      candidate.securityClearance &&
      candidate.yearsOfExperience &&
      candidate.idealJobTitle &&
      candidate.relocationprefrence;

    if (!hasCompleteProfileInfo) {
      steps.push(2); // Step 2: Complete profile
    }

    // Check if the user is a transitioning service member
    const hasSkillbridgeProfileInfo =
      candidate.skillbridgeStartDate &&
      candidate.skillbridgeEndDate &&
      candidate.duringSkillbridgeCity &&
      candidate.duringSkillbridgeState &&
      candidate.afterskillbridgeCity &&
      candidate.afterskillbridgeState;

    if (
      candidate.type === "Transitioning Service Member" &&
      !hasSkillbridgeProfileInfo
    ) {
      steps.push(3); // Step 3: Skillbridge profile
    }

    // Check if candidate's military background fields are filled
    const hasMilitaryBackground =
      candidate.branch &&
      candidate.rank &&
      candidate.mos &&
      candidate.yearsServed;

    if (!hasMilitaryBackground) {
      steps.push(3); // Step 3: Military background
    }

    // Check if candidate has a profile photo
    const hasProfilePhoto = candidate.headshotLink;

    if (!hasProfilePhoto) {
      steps.push(4); // Step 4: Profile photo
    }

    setIncompleteSteps(steps);
    if (steps.length > 0) {
      setCurrentStep(steps[0]); // Show the first incomplete step
    } else {
      navigate("/admin/dashboard"); // If all steps are complete
    }
  }, [candidate, navigate]);

  if (loading) {
    return (
      <div className="loading-container">
        <img src={logo} className="logo-pre-loader" />
        <div className="spinner"></div>

        <div>
          <h6>Please Wait..</h6>
        </div>
      </div>
    );
  }

  if (!candidate) {
    navigate("/admin/login");
  }

  // Handling steps navigation
  const nextStep = () => {
    const currentIndex = incompleteSteps.indexOf(currentStep);
    if (currentIndex < incompleteSteps.length - 1) {
      setCurrentStep(incompleteSteps[currentIndex + 1]);
    }
  };

  const prevStep = () => {
    const currentIndex = incompleteSteps.indexOf(currentStep);
    if (currentIndex > 0) {
      setCurrentStep(incompleteSteps[currentIndex - 1]);
    }
  };

  const progressPercentage =
    ((incompleteSteps.indexOf(currentStep) + 1) / incompleteSteps.length) * 100;

  return (
    <div className="dashboard company-profile-page">
      <Header candidate={candidate} />
      <ToastContainer />
      <div className="row dashboardrow" style={{ minHeight: "100vh" }}>
        <div
          className={`col-lg-2 col-md-2 col-sm-4 col-4 sidebar ${
            isSidebarCollapsed ? "sidebarCollapsed" : ""
          }`}
        >
          <Sidebar
            onToggleSidebar={setIsSidebarCollapsed}
            candidate={candidate}
          />
        </div>
        <div
          className={`col-lg-10 col-md-10 col-sm-8 col-8 sidebar-column contentbar ${
            isSidebarCollapsed ? "contentExpanded" : ""
          }`}
        >
          <main>
            <h4 className="main-heading">Complete Your Profile to Go Public</h4>
            <p>
              You're almost there! Just a few more steps to finalize your
              profile and make it visible to hiring companies. Let’s get it
              done!
            </p>

            {/* Progress Bar */}
            <div
              className="progress-bar-container"
              style={{ marginBottom: "20px" }}
            >
              <div className="progress-bar">
                <div
                  className="progress"
                  style={{
                    width: `${progressPercentage}%`,
                    position: "relative",
                    backgroundColor: "#ffc500",
                    height: "20px",
                    borderRadius: "5px",
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      textAlign: "center",
                      color: "#fff",
                      fontWeight: "bold",
                      left: "50%",
                      transform: "translateX(-50%)", // Center the text
                    }}
                  >
                    {`${Math.round(progressPercentage)}%`}
                  </span>
                </div>
              </div>
            </div>

            {/* Form Steps */}
            {currentStep === 1 && (
              <div>
                <BasicInformationUpdateProfile candidate={candidate} />
              </div>
            )}
            {currentStep === 2 && (
              <div>
                <CompleteProfileAbout candidate={candidate} />
              </div>
            )}

            {currentStep === 3 && (
              <div>
                <CompleteProfileMilitaryBackground
                  candidate={candidate}
                  onClose={onClose}
                />
              </div>
            )}

            {currentStep === 4 && (
              <div className="popup-content">
                <CompleteProfileImageUplaod candidate={candidate} />
              </div>
            )}

            {/* Navigation Buttons */}
            <div className="step-buttons">
              {/* {incompleteSteps.length > 1 && currentStep > 1 && (
                <button onClick={prevStep} className="btn prev-btn">
                  Previous
                </button>
              )} */}
              {/* {incompleteSteps.length > 1 &&
                incompleteSteps.indexOf(currentStep) <
                  incompleteSteps.length - 1 && (
                  <button onClick={nextStep} className="btn next-btn">
                    Next
                  </button>
                )} */}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default CandidateCompleteProfile;

import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../../components/admin/dashboard/Header/Header";
import logo from "../../assets/imgs/logo-7eagle.png";
import profilebanner from "../../assets/imgs/profilebanner1.png";
import branch_airforce from "../../assets/imgs/Branch-Air-Force.png";
import branch_army from "../../assets/imgs/Branch-Army.png";
import branch_coast_guard from "../../assets/imgs/Branch-Coast-Guard.png";
import branch_marine_corps from "../../assets/imgs/Branch-Marine-Corps.png";
import branch_navy from "../../assets/imgs/Branch-Navy.png";
import branch_space_force from "../../assets/imgs/Branch-Space-Force.png";
import { ToastContainer } from "react-toastify";
import ProfileWarning from "../../components/admin/dashboard/Profile Warning/ProfileWarning";
import AboutMe from "../../components/admin/dashboard/CandidateAbout/AboutCandidate";
import WatchMyIntroVideo from "../../components/admin/dashboard/Candidates/WatchMyIntro/WatchMyIntroVideo";
import Certification from "../../components/admin/dashboard/Candidates/Certifications/Certification";
import TargetIndustries from "../../components/admin/dashboard/Candidates/Target Industries/TargetIndustries";
import MilitaryBackground from "../../components/admin/dashboard/Candidates/Military Background/MilitaryBackground";
import ViewResume from "../../components/admin/dashboard/Candidates/Resume/ViewResume";
import "./AdminDashboard.css";

const CandidatePublicProfile = () => {
  const hideEditIcon = true;
  const navigate = useNavigate();
  const { userId } = useParams(); // Get userId from URL params

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalResumeOpen, setIsModalResumeOpen] = useState(false);
  const [candidate, setCandidate] = useState(null);
  const [loading, setLoading] = useState(true);

  // Modal open/close functions
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const openResumeModal = () => setIsModalResumeOpen(true);
  const closeResumeModal = () => setIsModalResumeOpen(false);

  useEffect(() => {
    const fetchCandidate = async () => {
      try {
        const config = {};
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/candidates/user/${userId}`,
          config
        );

        console.log("API Response:", response.data);
        setCandidate(response.data.data);
      } catch (error) {
        console.error(
          "Error during fetch candidate records",
          error.response ? error.response.data : error.message
        );
      } finally {
        setLoading(false);
      }
    };

    fetchCandidate();
  }, [userId]); // Add userId as a dependency

  if (loading) {
    return (
      <div className="loading-container">
        <img src={logo} className="logo-pre-loader" alt="Loading logo" />
        <div className="spinner"></div>
        <div>
          <h6>Please Wait..</h6>
        </div>
      </div>
    );
  }

  if (!candidate) {
    navigate("/admin/login"); // Redirect to login
    return null; // Return null to prevent further rendering
  }

  const BASE_URL = `${process.env.REACT_APP_API_URL}`;
  const profileImage = `${BASE_URL}/${candidate.headshotLink}`;

  return (
    <div className="dashboard candidate-profile-page">
      <Header candidate={candidate} />
      <ToastContainer />
      <div className="profile-banner-candidate">
        <img
          src={profilebanner}
          alt="profile-banner"
          style={{ width: "100%" }}
        />
        <div className="branch-logo">
          {candidate.branch === "Army" && (
            <img src={branch_army} alt="branch-army" />
          )}
          {candidate.branch === "Air Force" && (
            <img src={branch_airforce} alt="branch-airforce" />
          )}
          {candidate.branch === "Navy" && (
            <img src={branch_navy} alt="branch-navy" />
          )}
          {candidate.branch === "Space Force" && (
            <img src={branch_space_force} alt="branch-space-force" />
          )}
          {candidate.branch === "Coast Guard" && (
            <img src={branch_coast_guard} alt="branch-coast-guard" />
          )}
          {candidate.branch === "Marine Corps" && (
            <img src={branch_marine_corps} alt="branch-marine-corps" />
          )}
        </div>
      </div>

      <div className="container">
        <div className="row dashboardrow" style={{ minHeight: "100vh" }}>
          <div className="col-lg-3 col-md-3 col-sm-4 col-12">
            <div className="profile-box-candidate">
              <div className="candidate_type">
                <span className="candidateType">{candidate.type}</span>
              </div>
              <div className="candidate-profile-func">
                {candidate.headshotLink ? (
                  <div className="exist-profile-image candidate-profile-img">
                    <img
                      src={profileImage}
                      alt="ProfileImg"
                      className="Profile-Img"
                    />
                  </div>
                ) : (
                  <div className="ProfileDefaultImg">
                    <h2>{candidate.name.charAt(0)}</h2>
                  </div>
                )}
              </div>
              <div className="candidate-info">
                <h4>{candidate.name}</h4>
                <span>{candidate.branch}</span>
                <div>
                  <button
                    onClick={openResumeModal}
                    className="profile-view-resume"
                  >
                    View Resume
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-md-9 col-sm-8 col-12">
            <main>
              {candidate.videoIntroUrl && (
                <WatchMyIntroVideo
                  candidate={candidate}
                  hideEditIcon={hideEditIcon}
                />
              )}
              {(candidate.description ||
                candidate.securityClearance ||
                candidate.yearsOfExperience ||
                candidate.idealJobTitle ||
                candidate.relocationprefrence) && (
                <AboutMe candidate={candidate} hideEditIcon={hideEditIcon} />
              )}
              {(candidate.certifications &&
                candidate.certifications.length > 0) ||
              (candidate.Pursuingcertifications &&
                candidate.Pursuingcertifications.length > 0) ? (
                <Certification
                  candidate={candidate}
                  hideEditIcon={hideEditIcon}
                />
              ) : null}
              {candidate.targetIndustries &&
                candidate.targetIndustries.length > 0 && (
                  <TargetIndustries
                    candidate={candidate}
                    hideEditIcon={hideEditIcon}
                  />
                )}
              {candidate.branch &&
                candidate.rank &&
                candidate.mos &&
                candidate.yearsServed && (
                  <MilitaryBackground
                    candidate={candidate}
                    hideEditIcon={hideEditIcon}
                  />
                )}
            </main>
          </div>
        </div>
      </div>
      <ViewResume
        isOpen={isModalResumeOpen}
        onClose={closeResumeModal}
        candidate={candidate}
      />
    </div>
  );
};

export default CandidatePublicProfile;

import React from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import IconDrag from "../../../../../assets/imgs/icon-move-line.png";
import "./ColumnEditModal.css";
import Cursor from "quill/blots/cursor";

const ItemType = {
  COLUMN: "column",
};

const ColumnEditModal = ({
  isOpen,
  onClose,
  columns,
  toggleColumnVisibility,
  onColumnReorder,
}) => {
  if (!isOpen) return null;

  const handleCheckboxChange = (col) => {
    toggleColumnVisibility(col.id); // Toggle visibility state
  };

  const moveColumn = (dragIndex, hoverIndex) => {
    const newColumns = [...columns];
    const [draggedColumn] = newColumns.splice(dragIndex, 1);
    newColumns.splice(hoverIndex, 0, draggedColumn);
    onColumnReorder(newColumns);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className="modal fade show"
        style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-edit-column">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Choose which columns to display</h5>
              <button
                type="button"
                className="btn-close"
                onClick={onClose}
              ></button>
            </div>
            <div className="modal-body edit-column-box">
              <div>
                <h6>SYSTEM FIELDS</h6>
                <div className="leftsie_column_edit">
                  {columns.map((col) => (
                    <div key={col.id} className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={col.visible}
                        onChange={() => handleCheckboxChange(col)}
                      />
                      <label className="form-check-label">{col.name}</label>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <h6>DISPLAYED COLUMNS</h6>
                <div className="rightside_column_edit">
                  {columns
                    .filter((col) => col.visible)
                    .map((col, index) => (
                      <DraggableColumn
                        key={col.id}
                        index={index}
                        col={col}
                        moveColumn={moveColumn}
                        style={{ Cursor: "pointer" }}
                      />
                    ))}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn btn-save" onClick={onClose}>
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

const DraggableColumn = ({ col, index, moveColumn }) => {
  const [, ref] = useDrag({
    type: ItemType.COLUMN,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemType.COLUMN,
    hover: (item) => {
      if (item.index !== index) {
        moveColumn(item.index, index);
        item.index = index;
      }
    },
  });

  return (
    <div
      ref={(node) => ref(drop(node))}
      style={{
        padding: "8px",
        marginBottom: "10px",
        fontSize: "15px",
        backgroundColor: "#E6F3FF",
        border: "1px solid #E6F3FF",
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <img
        src={IconDrag}
        alt="drag icon"
        style={{
          width: "20px",
          marginRight: "10px",
        }}
      />
      {col.name}
    </div>
  );
};

export default ColumnEditModal;

import React, { useState } from "react";
import "./JobSearchStatus.css";
import plusIcon from "../../../../../assets/imgs/Edit.png";
import UpdateJobSearchSlider from "./UpdateJobSearchSlider";

function JobSearchStatus({ candidate }) {
  // Track if the update form is displayed instead of job status
  const [isEditing, setIsEditing] = useState(false);
  const [updatedCandidate, setUpdatedCandidate] = useState(candidate); // Track updated candidate data

  // Toggle editing state to show/hide the update form
  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  // This function will be passed as onUpdate to the child component
  const handleUpdate = (updatedData) => {
    setUpdatedCandidate({
      ...updatedCandidate,
      jobSearchStatus: updatedData.jobSearchStatus,
      jobSearchStatusName: updatedData.jobSearchStatusName,
    });
  };

  return (
    <div className="basicInformation">
      <h4>Job Search Status</h4>

      {isEditing ? (
        <UpdateJobSearchSlider
          candidate={updatedCandidate} // Pass the updated candidate to the child
          onUpdate={handleUpdate} // Callback to handle update in parent
          onClose={toggleEdit} // Close form after submission
        />
      ) : (
        <div className="job-status-container">
          <div className="job-status">
            <h4 className="job_search_number">
              {updatedCandidate.jobSearchStatus || candidate.jobSearchStatus}
            </h4>
            <p>
              {updatedCandidate.jobSearchStatusName ||
                candidate.jobSearchStatusName}
            </p>
          </div>
          <div className="editIcon" onClick={toggleEdit}>
            <img src={plusIcon} alt="Edit icon" />
          </div>
        </div>
      )}
    </div>
  );
}

export default JobSearchStatus;

import React, { useState, useContext } from "react";
import Modal from "react-modal";
import "./AddMilitary.css";
import { AuthContext } from "../../../../../../context/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import CompleteProfileMilitaryBackground from "../CompleteProfileMilitaryBackground";
Modal.setAppElement("#root");

function AddMilitary({ isOpen, onClose, candidate, onUpdate }) {
  return (
    <>
      <ToastContainer />

      <CompleteProfileMilitaryBackground
        candidate={candidate}
        onClose={onClose}
        onUpdate={onUpdate} // Pass callback to handle updates
      />
    </>
  );
}

export default AddMilitary;

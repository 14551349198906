import React, { useState, useEffect, useRef } from "react";
import "./AddNewFilter.css"; // For styling the modal

// Import icons
import iconSearch from "../../../../../assets/imgs/icon-search-fill.png";
import iconUser from "../../../../../assets/imgs/icon-user-fill.png";
import iconSetting from "../../../../../assets/imgs/icon-gear-fill.png";
import iconJob from "../../../../../assets/imgs/icon-job-fill.png";
import iconDate from "../../../../../assets/imgs/Date_fill.png";
import iconPin from "../../../../../assets/imgs/icon-pin-fill.png";
import iconSheild from "../../../../../assets/imgs/icon-shield-fill.png";
import iconAward from "../../../../../assets/imgs/icon-award-fill.png";
import iconIndustry from "../../../../../assets/imgs/icon-industry-fill.png";
import iconCompany from "../../../../../assets/imgs/icon-company-fill.png";
import iconFire from "../../../../../assets/imgs/icon-fire-fill.png";
import iconUserAdd from "../../../../../assets/imgs/icon-useradd-fill.png";
import iconActivity from "../../../../../assets/imgs/icon-useractivity-fill.png";
import iconInActivity from "../../../../../assets/imgs/icon-userinactive-fill.png";

const AddFilterModal = ({ isOpen, onClose, onFilterClick }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const modalRef = useRef(null); // Reference for the modal content

  // Map filters to icons
  const filterIcons = {
    Manager: iconUser,
    "Text Search": iconSearch,
    "Company Name": iconUserAdd,
    "Current Plan": iconSheild,
    "Verification Status": iconJob,
    "Availability Date": iconDate,
    "Radius Search (Location)": iconPin,
    "Security Clearance": iconSheild,
    Certifications: iconAward,
    "Target Industries": iconIndustry,
    "Relocation Option": iconPin,
    Company: iconCompany,
    Job: iconJob,
    Stage: iconSetting,
    "Hot Candidate": iconFire,
    Affiliate: iconUser,
    "Last Activity": iconActivity,
    "Show Inactive Candidates": iconInActivity,
    "Date Joined": iconDate,
  };

  const allFilters = Object.keys(filterIcons);

  // Filter options based on search input
  const filteredFilters = allFilters.filter((filter) =>
    filter.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose(); // Close the modal if clicked outside
      }
    };

    // Add event listener when modal is open
    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    // Cleanup event listener
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null; // Do not render modal if not open

  return (
    <div className="modal-main">
      <div className="modal-content" ref={modalRef}>
        <input
          type="text"
          placeholder="Type or select a filter"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-bar"
        />
        <div className="modal-body">
          {/* Filter List */}
          <ul className="filter-list">
            {filteredFilters.map((filter, index) => (
              <li
                key={index}
                className="filter-item"
                onClick={() => onFilterClick(filter)} // Handle click on filter
              >
                <img
                  src={filterIcons[filter]} // Icon for the filter
                  alt={`${filter} icon`}
                  className="filter-icon" // Add CSS class for styling icons
                />
                {filter}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AddFilterModal;

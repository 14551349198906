// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profileWarning-box{
    border: 1px solid #dc6903;
    display: flex;
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
    background-color: #FFFAEB;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.profileWarning-box .profileIcon img{
    width: 50px;
    margin-right: 10px;
}


.profileWarning-box .profile-description{
   display: flex;
}

.profile-description h4{
    color: #de6f12;
    font-weight: 0600;
    font-size: 22px;
}

.profile-description p{
    color: #6B5202;
    font-size: 16px;
}
.profile-description ul li{
    color: #6B5202;
    font-weight: 600;
}

.complete-profile-btn{
    background-color: #f1ba02;
    border: none;
    padding: 12px 40px;
    border-radius: 10px;
    color: #6c5202;
    font-weight: 500;
  
}

.hide-box{
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/components/admin/dashboard/Profile Warning/ProfileWarning.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,yBAAyB;IACzB,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;;;AAGA;GACG,aAAa;AAChB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;AACA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;IACd,gBAAgB;;AAEpB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;AACnB","sourcesContent":[".profileWarning-box{\n    border: 1px solid #dc6903;\n    display: flex;\n    padding: 20px;\n    border-radius: 10px;\n    margin-top: 20px;\n    background-color: #FFFAEB;\n    justify-content: space-between;\n    align-items: center;\n    position: relative;\n}\n\n.profileWarning-box .profileIcon img{\n    width: 50px;\n    margin-right: 10px;\n}\n\n\n.profileWarning-box .profile-description{\n   display: flex;\n}\n\n.profile-description h4{\n    color: #de6f12;\n    font-weight: 0600;\n    font-size: 22px;\n}\n\n.profile-description p{\n    color: #6B5202;\n    font-size: 16px;\n}\n.profile-description ul li{\n    color: #6B5202;\n    font-weight: 600;\n}\n\n.complete-profile-btn{\n    background-color: #f1ba02;\n    border: none;\n    padding: 12px 40px;\n    border-radius: 10px;\n    color: #6c5202;\n    font-weight: 500;\n  \n}\n\n.hide-box{\n    position: absolute;\n    top: 10px;\n    right: 20px;\n    font-size: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
